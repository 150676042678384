/* PhotoSection style */
.marketplace_box-image {
	border-radius: 20px;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}
.photoSection {
	position: relative;
	max-width: 300px;
	height: 400px;
	box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
	margin-right: 20px;
	display: flex;
    align-content: center;
	justify-content: space-between;
    align-items: center;
    flex-direction: column;
}
.img_change {
	margin-bottom: 5px;
    padding: 10px;
	width: 90%;
}
.alarm :hover {
	cursor: pointer;
	transform: scale(1.1);
}
.setAlarm {
	display: inline-block;
	position: relative;
}
.setAlarm:hover::after {
	content: attr(data-title);
	position: absolute;
	left: 50%;
	top: 40%;
	z-index: 1;
	background: rgba(237, 237, 234, 0.9);
	font-size: 11px;
	color: #3f3f3f;
	padding: 5px 5px;
	border: 1px solid rgb(216, 216, 216);
	border-radius: 5px;
}
.alarm_singleM {
	position: absolute;
    padding-left: 10px;
    padding-top: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 30px;
}
.alarm_singleM :hover {
	cursor: pointer;
	transform: scale(1.1);
}
.setAlarm_singleM {
	display: inline-block;
	position: relative;
}
.setAlarm_singleM:hover::after {
	content: attr(data-title);
	position: absolute;
	left: 50%;
	top: 40%;
	z-index: 1;
	background: rgba(237, 237, 234, 0.9);
	font-size: 11px;
	color: #3f3f3f;
	padding: 5px 5px;
	border: 1px solid rgb(216, 216, 216);
	border-radius: 5px;
}
@media screen and (max-width: 767px) {
	.marketplace_box-image {
		width: 100%;
	}
	.photoSection {
		margin-right: 0;
		max-width: none;
		width: 100%;
	}
}
/* Schedule style for modal */
.scheduleForm {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
}
.scheduleForm__container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
	justify-content: center;
}
.scheduleForm__heading {
	margin-bottom: 30px;
	font-family: Inter, sans-serif;
	font-size: 24px;
	line-height: 28px;
	font-weight: 400;
	color: rgba(0, 0, 0, 0.5);
}
.scheduleForm__container span {
	display: block;
	font-weight: 300;
	font-size: 16px;
	line-height: 18.75px;
	color: #424242;
	margin-bottom: 7px;
}
.scheduleForm__select {
	width: 200px;
	height: 34px;
	margin-bottom: 20px;
	border: 1px solid #c9cacc;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 5px;
}
.scheduleForm__button {
	width: 110px;
	height: 34px;
	background: linear-gradient(104.34deg, #d2d2d2 -15.34%, #a2a2a2 145.95%);
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	color: #fff;
	font-weight: 400;
	font-size: 16px;
	line-height: 18.75px;
	margin-top: 20px;
}
.scheduleForm__button:hover {
	cursor: pointer;
	transform: scale(1.02);
}
/* Update price and stock style for modal */
.updateForm {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
}
.updateForm__container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
	justify-content: center;
}
.updateForm__heading {
	margin-bottom: 30px;
	font-family: Inter, sans-serif;
	font-size: 24px;
	line-height: 28px;
	font-weight: 400;
	color: rgba(0, 0, 0, 0.5);
}
.updateForm__container span {
	display: block;
	font-weight: 300;
	font-size: 16px;
	line-height: 18.75px;
	color: #424242;
	margin-bottom: 7px;
}
.updateForm__input {
	width: 200px;
	height: 34px;
	margin-bottom: 20px;
	border: 1px solid #c9cacc;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 5px;
}
.updateForm__button {
	width: 110px;
	height: 34px;
	background: linear-gradient(104.34deg, #d2d2d2 -15.34%, #a2a2a2 145.95%);
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	color: #fff;
	font-weight: 400;
	font-size: 16px;
	line-height: 18.75px;
	margin-top: 20px;
}
.updateForm__button:hover {
	cursor: pointer;
	transform: scale(1.02);
}
.updateForm__delete-button {
	width: 60px;
	height: 34px;
	background: #ce0000;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	color: #fff;
	font-weight: 400;
	font-size: 16px;
	line-height: 18.75px;
	margin-top: 20px;
}
.updateForm__delete-button:hover {
	cursor: pointer;
	transform: scale(1.02);
}
