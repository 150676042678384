.cm-title-button {
  background: #af1b3f;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

/* Table START */
.countries-pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.cm-reg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0 25%;
  width: 300px;
  height: 100%;
  padding: 0 10px;
}
.cm-flag {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0 10%;
  width: 100px;
  height: 100%;
}
.cm-delete {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0 10%;
  width: 50px;
  height: 100%;
  padding: 0 10px;
}
.cm-delete-button {
  padding: 3px;
}
.cm-delete-button:hover {
  cursor: pointer;
  transform: scale(1.2);
}
/* Table END */

/* Modal START */
.cm-modal-cont {
  max-height: 700px;
  /* width: 520px; */
  overflow-y: auto;
  padding: 10px;
}
.cm-modal-title-text {
  font-weight: bold;
  font-size: 28px;
  line-height: 25px;
  color: #000000;
}
.cm-modal-cat {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.cm-modal-text {
  font-size: 16px;
  line-height: 30px;
  color: #5a5a5a;
  padding-bottom: 5px;
  text-transform: initial;
}
.cm-modal-input {
  font-size: 16px;
  line-height: 30px;
  color: #5a5a5a;
  background: #f8f8f8;
  border: 1px solid #acacac;
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
  height: 40px;
  padding-left: 5px;
}
.cm-import-reviews-dropzone {
  background: #f8f8f8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cm-import-reviews-dropzone-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #5a5a5a;
}
/* Modal END */
