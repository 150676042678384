.fm-table-overflow {
	overflow-x: auto;
	position: relative;
	display: block;
	overflow: auto;
	padding: 0 20px;
	width: 100%;
	max-width: 1880px;
}

/* Table START */
.fm-num {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	flex: 0 7%;
	width: 50px;
	height: 100%;
	padding: 0 10px;
}
.fm-source {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	flex: 0 100%;
	width: 300px;
	height: 100%;
	padding: 0 10px;
	transition: width 2s;
}
.fm-reg {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	flex: 0 25%;
	width: 300px;
	height: 100%;
	padding: 0 10px;
}
.fm-date {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	flex: 0 30%;
	width: 300px;
	height: 100%;
	padding: 0 10px;
}
.fm-sourse-copy-icon {
	opacity: 0;
}
.fm-source:hover .fm-sourse-copy-icon {
	opacity: 1;
}
.fm-sourse-text:hover {
	text-decoration: underline;
}
.fm-sourse-copy:hover {
	transform: scale(1.05);
}
/* Table END */
