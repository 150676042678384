.pricing-orange {
  color: #f4733c;
}
.pricing-orangebg {
  background: #f4733c;
}
.pricing-text,
.pricing-text-grey {
  font-weight: normal;
  font-size: 15px !important;
  line-height: 18px !important;
  color: #5a5a5a;
}
.pricing-text-grey {
  color: #acacac;
}
.pricing-line-active {
  display: flex;
  background: #f4733c;
  border-radius: 3px;
}
.pricing-line {
  display: flex;
  background: transparent;
  border-radius: 3px;
}
.pricing-line-text {
  color: #f4733c;
  margin-left: 7px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.pricing-graph-box {
  position: relative;
  width: 100%;
  min-height: 330px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
  border: 2px solid #e1e1e1;
  box-sizing: border-box;
  /* box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.03); */
  border-radius: 12px;
  margin: 10px 0;
  padding: 20px;
}
.pricing-graph-hide {
  position: absolute;
  top: 9px;
  right: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.pricing-graph-hide-text {
  margin-right: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: transparent;
}
.pricing-graph-hide:hover .pricing-graph-hide-text {
  color: #55718a;
}
.pricing-graph-box:hover {
  border: 2px solid #acacac;
}
.pricingTooltopgraph {
  color: #666;
  background-color: #fff;
  margin: -4px -8px;
  padding: 15px;
  border: 1px solid #6667;
  box-shadow: 1px 1px 2px 0px #6667;
  font-size: 1.4em;
  font-weight: 400;
}

.pricingTooltopgraph .servicegraph {
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  text-transform: uppercase;
}
.textgraph {
  font-size: 14px;
  line-height: 25px;
  color: #5a5a5a;
}
.pricing-progress-cont {
  margin: 5px 0;
  overflow: auto;
  width: 100%;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.seller-product-tab {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #2b5ec5 !important;
  text-transform: initial !important;
  border-bottom: 1px solid transparent !important;
  margin: 10px !important;
  box-shadow: none !important;
}
.active-tab-default,
.seller-product-tab.Mui-selected,
.seller-product-tab:active,
.seller-product-tab:hover,
.seller-product-tab:focus {
  color: #5a5a5a !important;
  border-bottom: 1px solid #5a5a5a !important;
  font-weight: 700 !important;
}

.stan-acc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 0 1 100%;
  padding-left: 20px;
}
.stan-rule,
.stan-cond,
.stan-cat1,
.stan-cat2 {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.stan-rule {
  justify-content: flex-start;
  padding-left: 60px;
  width: 150px;
  flex: 0 1 15%;
}
.stan-cond {
  justify-content: flex-start;
  flex: 0 1 29%;
}
.stan-cat1 {
  justify-content: center;
  flex: 0 1 28%;
}
.stan-cat2 {
  justify-content: space-around;
  flex: 0 1 30%;
}
.stand-actions-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border: none;
  box-shadow: none;
  background: transparent;
}
.stand-actions-cont:hover {
  color: #006dd2;
  fill: #006dd2;
}
.rules-modal-cont {
  width: 100%;
  min-width: 420px;
  max-width: 480;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.rules-delete-bacldrop {
  background-color: #fff;
  width: 380px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pricing-event-modal-active:hover {
  background: #e5e5e5;
}
.new-pricing-column {
  /* width: 740px; */
  width: 860px;
  margin-bottom: 40px;
}
.new-pricing-cont {
  background: #ffffff;
  border: 1px solid #e1e1e1;
  width: 100%;
  margin: 25px auto 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 25px;
}
.new-pricing-text,
.new-pricing-title-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #5a5a5a;
}
.new-pricing-title-text {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}
.new-pricing-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 25px;
}
.new-pricing-date {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 200px;
}
.new-pricing-save {
  background: #acacac;
  border-radius: 6px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 10px 30px;
}
.new-pricing-discard {
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #5a5a5a;
  background: transparent;
  box-shadow: none;
}
.new-pricing-search {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #55718a;
  background: #ffffff;
  border: 1px solid #55718a;
  border-radius: 5px;
  padding: 10px 20px;
}
.new-pricing-modal-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #e1e1e1;
  width: 100%;
}
.new-pricing-overflow {
  max-height: 400px;
  height: max-content;
  width: 100%;
  overflow-y: auto;
}
.new-pricing-choose-file {
  background: #ffffff;
  border: 1px solid #55718a;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #55718a;
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.pricing-event-coutry-cont {
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.pricing-event-coutry-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 0 1 48%;
}
.pricing-event-cat-cont {
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.pricing-event-cat-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 0 1 48%;
  margin-bottom: 10px;
}
.pricint-event-export,
.pricint-event-abort {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  background: #ffffff;
  border-radius: 6px;
  padding: 10px 25px;
}
.pricint-event-export {
  border: 1px solid #55718a;
  color: #55718a;
}
.pricint-event-abort {
  border: 2px solid #ec0000;
  color: #e80000;
}
.pricing-timeline-btn {
  background: #ffffff !important;
  border: 1px solid #55718a !important;
  border-radius: 4px !important;
  padding: 7px 11px !important;
  box-shadow: none !important;
  text-transform: initial !important;
  color: #55718a !important;
  font-size: 14px !important;
  line-height: 16px !important;
  font-weight: 400 !important;
}
.pricing-timeline-btn:focus {
  color: #ffffff !important;
  background: #5a5a5a !important;
  border: 1px solid #5a5a5a !important;
}
.pricing-timeline-table-title {
  min-width: 260px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: sticky;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 110;
  height: 59px;
  border-right: 2px solid #e1e1e1;
}
.pricing-week-tooltip-cont {
  font-size: 13px;
  line-height: 15px;
  color: #5a5a5a;
  text-align: center;
}
.pricing-timeline-table-week {
  width: 50px;
  border-right: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pricing-week-text {
  font-size: 20px;
  line-height: 23px;
  color: #5a5a5a;
}
.pricing-event-sticky-block {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 100;
}
.pricing-event-sticky-block:hover {
  background-color: #f2f2f2;
}
@media screen and (max-width: 1440px) {
  .pricing-event-sticky-block {
    width: 650px;
  }
}
@media screen and (max-width: 991px) {
  .gestao-sticky-block {
    position: initial;
    width: auto;
  }
}
@media screen and (max-width: 769px) {
  .new-pricing-column {
    width: 300px;
  }
}
