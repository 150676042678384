.filter-button {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 7px;
  padding: 9px 12px;
  background: transparent;
  border: 1px solid #acacac;
  border-radius: 5px;
  box-shadow: none;
}
.filter-button p {
  color: #5a5a5a;
  font-size: 14px;
}
.filter-button span {
  color: #f1692e;
  font-weight: 700;
}
.filter-modal-back {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.3);
  transition: 0.3s ease;
}
.filter-modal {
  background-color: white;
  position: fixed;
  top: 0;
  z-index: 10002;
  width: 320px;
  transition: 0.3s ease;
  padding: 20px 10px 40px;
  height: 100vh;
  overflow-y: scroll;
}
.filter-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 17px;
}
.filter-top p {
  font-weight: 700;
  font-size: 21px;
  color: #5a5a5a;
}
.filter-top span {
  color: #f1692e;
}
.filter-top button {
  padding: 0;
  background: transparent;
  color: #55718a;
  font-weight: 500;
  font-size: 14px;
  box-shadow: none;
}
.filter-modal-main {
  scrollbar-width: thin;
}
.filter-modal-main::-webkit-scrollbar {
  width: 0.4em;
}
.filter-modal-main::-webkit-scrollbar-track {
  background: white;
}
.filter-modal-main::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}
