.compa-select-cont {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #5A5A5A;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.compa-select-text {
    text-transform: uppercase;
    font-weight: 500;
}
.compa-select {
    width: 130px;
    height: 40px;
    background: #E2E2E2;
    border-radius: 5px;
    padding: 10px;
    margin: 5px 20px;
}
.compa-sku {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 5%;
    padding-right: 5px;
    text-align: center;
    width: 100px; 
    height: 100%;
}
.compa-ean {
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 8%;
    text-align: center;
    width: 140px;
    height: 100%;
}
.compa-title {
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 0 25%;
    width: 250px;
    height: 100%;
    padding-left: 10px;
}
.compa-vinuus {
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 12%;
    width: 150px; 
    height: 100%;
}
.compa-bodeboca {
    padding-right: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 0 12%;
    position: relative;
    width: 150px; 
    height: 100%;
}
.compa-amazon {
    padding-right: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 0 12%;
    position: relative;
    width: 150px; 
    height: 100%;
}
.compa-dott {
    padding-right: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 0 12%;
    position: relative;
    width: 150px; 
    height: 100%;
}
.compa-fnac {
    padding-right: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 0 12%;
    position: relative;
    width: 150px; 
    height: 100%;
}
.compa-sku-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #5A5A5A;
}
.compa-ean-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #5A5A5A;
}
.compa-title-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #5A5A5A;
}
.compa-cost-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #5A5A5A;
}
.compa-cost-update {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #b4b4b4;
    border-bottom: 1px solid #b4b4b4;
    cursor: pointer;
}
.compa-cost-update:hover {
    color: #af1b3f;
}
.compa-arrow {
    margin-bottom: 5px;
    transform: rotate(180deg);
}
.compa-box {
    display: flex;
    flex-direction: row;
}
.compa-box-update {
    display: flex;
    flex-direction: column;
}
@media screen and (max-width: 767px) {
    .compa-select-cont {
        flex-direction: column;
    }
    .compa-select-text {
        margin-bottom: 20px;
    }
    .compa-sku-text {
        margin: 10px 0;
    }
    .compa-ean-text {
        margin: 10px 0;
    }
    .compa-title-text {
        margin: 10px;
    }
    .compa-box-update {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin: 10px 0;
    }
    .compa-box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 10px 0;
    }
}
