.warehouse-name,
.warehouse-country,
.warehouse-partner,
.warehouse-status,
.warehouse-contact,
.warehouse-fees {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
}
.warehouse-name {
  width: 250px;
  flex: 0 1 22%;
  justify-content: flex-start;
}
.warehouse-country {
  width: 300px;
  flex: 0 1 20%;
  justify-content: flex-start;
}
.warehouse-partner {
  width: 250px;
  flex: 0 1 22%;
  justify-content: flex-start;
}
.warehouse-status {
  width: 250px;
  flex: 0 1 12%;
  justify-content: center;
}
.warehouse-contact {
  width: 150px;
  flex: 0 1 12%;
  justify-content: center;
}
.warehouse-fees {
  width: 150px;
  flex: 0 1 12%;
  justify-content: center;
}
.warehouse-modal-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: Roboto;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #5a5a5a;
  background: #fff !important;
  width: 190px;
  height: 40px;
  border: 1px solid #5a5a5a;
  box-sizing: border-box;
  border-radius: 6px;
  margin-right: 12px;
  margin: 5px 0;
  padding-left: 5px;
}
.warehouse-modal-select:active,
.warehouse-modal-select:focus {
  font-weight: 500;
  background: #5a5a5a;
}
.warehouse-modal-cont {
  max-height: 700px;
  width: 520px;
  overflow-y: auto;
  padding: 10px;
}
.warehouse-modal-title-text {
  font-weight: bold;
  font-size: 21px;
  line-height: 25px;
  color: #000000;
}

.logistic-modal-input {
  font-size: 16px;
  line-height: 30px;
  color: #5a5a5a;
  background: #f8f8f8;
  border: 1px solid #acacac;
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
  height: 40px;
  padding-left: 5px;
  margin: 5px 0;
  resize: none;
}

.s-warehouse-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-top: 40px;
  margin-bottom: 20px;
}
.s-warehouse-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  padding: 20px;
}
.s-warehouse-services {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
}
.s-warehouse-text {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 30px !important;
  color: #5a5a5a !important;
}

.service-name,
.service-amount,
.service-unit,
.service-note {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
}
.service-name {
  width: 450px;
  flex: 0 1 60%;
  justify-content: flex-start;
}
.service-amount {
  width: 200px;
  flex: 0 1 18%;
  justify-content: center;
}
.service-unit {
  width: 200px;
  flex: 0 1 12%;
  justify-content: center;
}
.service-note {
  width: 200px;
  flex: 0 1 10%;
  justify-content: center;
}
.service-modal-cont {
  max-height: 700px;
  width: 380px;
  overflow-y: auto;
  padding: 10px;
}
.service-modal-textarea {
  width: 100%;
  height: 150px;
  overflow: auto;
  background: #ffffff;
  border: 1px solid #acacac;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px;
  font-size: 14px;
  line-height: 20px;
  color: #5a5a5a;
}

/* Logistic Partners */
.partner-modal-cont {
  max-height: 700px;
  width: 520px;
  overflow-y: auto;
  padding: 10px;
}
.partner-modal-dropzone {
  background: #d8ceb9;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.partner-modal-dropzone-text {
  font-weight: normal;
  font-size: 30px;
  font-weight: 900;
  line-height: 30px;
  color: white;
}
.partner-modal-title-text {
  font-weight: bold;
  font-size: 21px;
  line-height: 25px;
  color: #000000;
}
.partner-modal-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: Roboto;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #5a5a5a;
  background: #fff !important;
  width: 120px;
  height: 40px;
  border: 1px solid #5a5a5a;
  box-sizing: border-box;
  border-radius: 6px;
  margin-right: 12px;
  margin: 5px 0;
  padding-left: 5px;
}
.partner-modal-select:active,
.partner-modal-select:focus {
  font-weight: 500;
  background: #5a5a5a;
}
.partner-name,
.partner-warehouses,
.partner-status,
.partner-contact {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
}
.partner-name {
  width: 450px;
  flex: 0 1 60%;
}
.partner-warehouses {
  width: 200px;
  flex: 0 1 18%;
}
.partner-status {
  width: 200px;
  flex: 0 1 12%;
}
.partner-contact {
  width: 200px;
  flex: 0 1 10%;
}
.partner-header-active {
  font-weight: bold;
  color: #5a5a5a;
  border-bottom: 2px solid #5a5a5a;
}
.rate-window-cont {
  max-height: 600px;
  width: 420px;
  overflow-y: auto;
  padding: 20px;
}
.rate-window-select {
  border: none;
  outline: none;
  scroll-behavior: smooth;
}

@media screen and (max-width: 991px) {
  .partner-modal-select {
    margin-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .partner-modal-cont {
    width: 100%;
  }
  .warehouse-modal-select {
    width: 120px;
  }
}
