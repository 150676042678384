.wrapper {
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #434242;
}

h1 {
	color: #f31212;
}

.containerInterval {
	width: 100vw;
	text-align: center;
}
