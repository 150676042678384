.select-field-user {
  width: 200px;
  height: 34px;
  margin-bottom: 20px;
  border: 1px solid #c9cacc;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px;
}

.s-users-management-container {
  width: 100%;
  margin-right: auto;
}

.create-user-box {
  text-align: left;
}

/* Acomp */
.users-email {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0 30%;
  width: 350px;
  padding-left: 15px;
}

.users-role {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0 20%;
  width: 150px;
}

.users-vendor-permission {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0 27%;
  width: 200px;
}

.users-username {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0 20%;
  width: 170px;
}

.users-status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0 15%;
  width: 120px;
}

.label-user-status {
  justify-content: center;
}

.users-status-btn {
  text-decoration-line: underline;
  color: #5a5a5a;
  background: transparent;
  box-shadow: none;
  border: none;
  text-transform: initial;
}

.users-status-btn:hover {
  cursor: pointer;
  opacity: 0.8;
}

.users-history {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0 10%;
  width: 100px;
}

.users-history-btn {
  text-decoration: underline;
  background: transparent;
  color: #5a5a5a;
  text-transform: capitalize;
  border: none;
  box-shadow: none;
  padding: 0;
}

.users-history-btn:hover {
  cursor: pointer;
  opacity: 0.8;
}

.users-edit {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 15%;
  width: 150px;
}

.email-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #5a5a5a;
}

.role-text-admin,
.role-text-user {
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  text-transform: lowercase;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px 10px;
}

.role-text-admin {
  background: #ffe6b5;
}

.role-text-user {
  background: #ffe7ff;
}

.permission-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #5a5a5a;
  padding-right: 20px;
  text-align: start;
}

.username-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #5a5a5a;
}

.edit-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.userm-modal-button-cancel {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #5a5a5a;
  background-color: #fff;
  border: none;
  box-shadow: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.formUser {
  height: 580px;
  overflow: auto;
  overflow-y: scroll;
  width: 600px;
  margin: 20px 0;
}

.user-title-sticky {
  position: sticky;
  top: 0;
  background-color: #fff;
  width: 100%;
  z-index: 1;
}

.formEdit__container-user {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 95%;
  padding: 0 10px;
  align-items: center;
  margin: auto;
  max-width: 550px;
  justify-content: space-between;
}

.formEdit__container-user span {
  display: block;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #5a5a5a;
  width: 100%;
  text-align: left;
}

.user-vendor-select {
  margin-top: -10px;
  height: 40px;
}

.user-vendor-select input {
  height: 100%;
}

@media screen and (max-width: 769px) {
  .formUser {
    width: 90%;
  }
}

.usermm-edit-acivity-cont td {
  flex: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.switch-width {
  width: 40px;
}

.switch-width span {
  width: 100%;
}

.switch-width span span {
  height: 13px;
  color: #fff;
}

.switch-width span span span {
  width: 13px;
  /* margin-top: -1px; */
}

.userm-edit-perm-text,
.userm-edit-perm-type-text {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 30px !important;
  color: #5a5a5a !important;
}

.userm-edit-perm-type-text {
  margin-right: 10px;
}
