.s_accessdenied {
    position: static;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 90vh;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.access_box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 614px;
    height: 378px;
    max-width: 614px;
    margin-right: 10px;
    margin-left: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.12);
    font-family: K2D, sans-serif;
}

.access_h1 {
    color: #d71f1f;
    font-weight: 400;
    text-align: center;
}

.redirect_back {
    font-size: 28px;
    line-height: 32px;
}

.timer {
    color: #d71f1f;
    font-size: 40px;
    line-height: 44px;
}

.accessdenied_button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px 40px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
    font-family: Inter, sans-serif;
    color: #444;
    font-size: 22px;
    line-height: 26px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
}

@media screen and (max-width: 767px) {

    .acceess_box {
        width: 450px;
        max-width: 450px;
        margin-right: 0px;
        margin-left: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }
}

@media screen and (max-width: 479px) {

    .acceess_box {
        width: 240px;
        max-width: 240px;
    }

    .accessdenied_button {
        height: 70px;
        margin-top: 15px;
    }
}