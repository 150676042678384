.req-prog-btn:active, .req-prog-btn:focus {
    border: 2px solid #00A02D;
    color: #00A02D;
}
.req-can-btn:active, .req-can-btn:focus {
    border: 2px solid #C80000;
    color: #C80000;
}
.req-fin-btn:active, .req-fin-btn:focus {
    border: 2px solid #5A5A5A;
    color: #5A5A5A;
}
.req-fin-btn, .req-can-btn, .req-prog-btn {
    border-radius: 6px;
    border: 2px solid #ACACAC;
    background: #FFF;
    color: #ACACAC;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px 15px;
    margin: 0 5px;
}
.req-modal-sys-lastupdate {
    color: #5A5A5A;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}
.not-table-btn {
    color: #55718A;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    border-radius: 15px;
    background: transparent;
    box-shadow: none;
    padding: 5px 10px;
}
.not-table-btn:active, .not-table-btn:focus {
    background: #FCE8A4;
}
.not-notifications {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1 1 70%;
    padding: 0 20px;
    flex-direction: row;
}
.not-pl-mkpl {
    display: flex;
    align-items: center;
    flex: 1 1 30%;
    padding: 0 20px;
    flex-direction: row;
}
.not-dotts-btn {
    border: 1px solid transparent;
    border-radius: 100%;
    margin-right: 20px;
}
.not-dotts-btn:hover {
    border: 1px solid #ACACAC;
}
.not-orange-dott {
    width: 8px;
    height: 8px;
    background: #F1692E;
    border-radius: 100%;
}
.not-table-cont {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #5A5A5A;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.not-cancel-btn {
    border-radius: 5px;
    border: 1px solid #ACACAC;
    background: #FFF;
    color: #5A5A5A;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    padding: 10px 30px;
    margin: 0 5px;
}
.not-delete-btn {
    padding: 10px 30px;
    border-radius: 5px;
    background: #55718A;
    color: #FFF;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    margin: 0 5px;
}
.not-select {
    border: none !important;

}
.app-drop-down-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
.app-drop-down-container button {
    flex: 1;
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
    box-shadow: none;
    width: 24px;
    display: flex;
    align-items: center;
  }
  
  .app-drop-down-container ul {
    margin: 0;
    padding: 0;
    position: absolute;
    top: calc(100% + 5px);
    min-width: 100%;
    width: max-content;
    display: flex;
    flex-direction: column;
    /* padding: 10px; */
    border-radius: 4px;
    box-shadow: 0 0 5px #d4d4d4;
    list-style: none;
    z-index: 1110;
  }
  
  .app-drop-down-container ul li{
    background-color: #fcfcfc;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color ease .2s;
  }
.not-menuitem-text {
    color: #5A5A5A;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 10px;
}
.not-menuitem-cont:hover {
    background-color: #e2e2e2;
}
.not-snot-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1 1 92%;
    padding: 0 20px;
    flex-direction: row;
}
.not-snot-offer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 8%;
    min-width: 100px;
    padding: 0 20px;
    flex-direction: row;
}
.not-modal1-close-btn {
    border-radius: 6px;
    border: 1px solid #55718A;
    background: #FFF;
    margin-top: 15px;
    color: #55718A;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px 20px;
}
.not-stepper-cont {
    border: 1px solid #E1E1E1;
    background: #FFF;
    padding: 20px 60px;
}
.not-step-title-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.not-step-cont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    color: #5A5A5A;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin-top: 20px;
}
.not-radio-modal-title {
    color: #5A5A5A !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-style: normal !important;
    line-height: 30px !important;
    height: 30px;
}
.not-checkbox-cont {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
.not-checkbox-box {
    flex: 0 1 48%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.not-set-title {
    color: #5A5A5A;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}
.not-set-white-cont {
    border-radius: 5px;
    background: #FFF;
    color: #5A5A5A;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 25px 20px;
}
.not-set-label-title {
    width: 80px;
}
