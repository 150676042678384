.trnl-cont {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  background: #e8edf2;
  padding: 20px 40px;
  width: 100%;
}

.trln-content-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  border-radius: 5px;
}

.trnl-filter-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 99.8%;
  min-height: 64px;
  margin-bottom: 5px;
  position: sticky;
  top: 60px;
  background: #fff;
  z-index: 101;
}

.trnl-lang-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 35%;
  margin-left: 17px;
}

.trnl-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: 17px;
}

.trnl-filter-button {
  background: #55718a;
  border-radius: 6px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 10px 20px;
}

.trnl-tabs-container {
  width: 100%;
  margin-top: 25px;
}

.trnl-app-bar {
  box-shadow: none !important;
  width: 100%;
}

.trnl-tabs-cont {
  box-shadow: none !important;
  border: none;
  background: #fff !important;
  padding: 0 20px;
  max-height: 34px;
}

.trnl-tab-box {
  font-family: Roboto !important;
  font-style: normal;
  font-size: 12px !important;
  line-height: 14px !important;
  display: flex;
  align-items: center;
  text-align: center;
  color: #006dd2 !important;
  border: none !important;
  box-shadow: none !important;
  max-width: 50px !important;
  background: #fff !important;
}

.trnl-tab-cont button {
  min-width: 250px !important;
}

.trnlm-tab-box:focus {
  text-decoration-line: none !important;
  color: #1d1d1d !important;
}

.trnl-field-section {
  padding: 10px 0;
  margin-bottom: 15px;
}

.trnl-field-label {
  color: #5a5a5a;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 5px 0 10px;
}

.trnl-items-section {
  padding: 10px 0;
}

.trnl-item-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.trnl-item-input {
  width: 48%;
  height: 100%;
}

.item-label {
  color: #5a5a5a;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  line-height: 16px;
  padding: 10px;
  height: 34px;
  resize: none;
}

.trnl-item-label {
  background-color: transparent !important;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  line-height: 16px;
  padding: 10px;
  border: 1px solid #828282;
  border-radius: 4px;
}

.trnl-submit-button-cont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  padding: 5px 0;
  position: relative;
  bottom: 0;
  height: 30px;
}
