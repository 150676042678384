.import-title {
  color: #5a5a5a;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.import-selects-cont {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.import-options {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  color: #000;
  width: 70%;
}

.import-options label {
  text-align: left;
  width: 130px;
}

.import-options label:nth-of-type(3) {
  width: 80px;
}

.pim-management-container .table-title-cont {
  flex-direction: row;
  justify-content: space-between;
}

.import-result {
  width: 400px;
  height: 320px;
  color: #000;
}

.import-error {
  overflow: auto;
  width: 100%;
  text-align: left;
}
