.single-billing-nav {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	padding-top: 40px;
	margin-bottom: 20px;
}
.single-billing-medium {
	padding-left: 12px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex: 0 15%;
	width: 130px;
	height: 100%;
}
.single-billing-large {
	padding-right: 5px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex: 0 30%;
	width: 300px;
	height: 100%;
}
.single-billing-small {
	padding-right: 5px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex: 0 10%;
	width: 100px;
	height: 100%;
}
.single-billing-total {
	padding-left: 15px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex: 0 20%;
	height: 100%;
}
.single-billing-total-small {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex: 0 13%;
	height: 100%;
}
.single-billing-total-large {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex: 0 60%;
	height: 100%;
}
.sb-filter-box {
	width: 100%;
	display: flex;
	margin-right: auto;
	margin-left: auto;
	justify-content: space-between;
	align-items: center;
	background: #e8edf2;
	padding: 20px 0;
}
.sb-filter-cont {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 99.8%;
	min-height: 64px;
	background: #ffffff;
	box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
	border-radius: 5px;
}
.sb-filter {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-left: 17px;
}
.export-btn {
	border: 1px solid #b5b5b5;
	border-radius: 5px;
	padding: 10px 15px;
	font-weight: bold;
	margin-right: 20px;
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 16px;
	color: #616161;
}

.billing-payment {
	background: #ffffff !important;
	border: 1px solid #e1e1e1 !important;
	box-sizing: border-box !important;
	border-radius: 5px !important;
	font-weight: 500 !important;
	padding: 5px 10px !important;
}

.billing-payment.in_payment {
	background: #ffeb8c !important;
	font-size: .7em;
}

.billing-payment.not_paid {
	color: #ed0000 !important;
	background: #fff5d3 !important;
}

.billing-payment.reversed {
	background: #ed0000 !important;
	color: #fff5d3 !important;
  font-size: .9em;
}

.billing-payment.paid {
	color: #029702 !important;
    background: #d4ffd3 !important;
}

.billing-payment.pending {
	color: #854000 !important;
  background: #ffb065 !important;
}

.billing-payment.canceled {
	color: #fff !important;
	background: #5a5a5a !important;
  font-size: .9em;
  opacity: .5;
}

.billing-payment.refunded,
.billing-payment.refunding {
	background: #ed4b00 !important;
	color: #fff5d3 !important;
  font-size: .9em;
}

@media screen and (max-width: 991px) {
	.sb-filter-box {
		margin-bottom: 20px;
		flex-direction: column;
	}

	.sb-filter-cont {
		flex-direction: column;
		padding: 10px 0;
	}
}
