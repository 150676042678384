.main-nav {
	width: 240px;
	height: 100%;
	top: 0;
	left: 0;
	overflow: auto;
	z-index: 10 !important;
}
.navigation-cont {
	display: flex;
	align-items: center;
	flex-direction: column;
	align-content: center;
	justify-content: space-between;
	color: #ffffff;
	height: 100%;
	width: 100%;
	/* padding-top: 30px; */
}
.nav-button {
	position: absolute;
	top: 15px;
	left: 0;
}
.arrow-nav {
	position: absolute;
	top: 20px;
	left: 225px;
}
.navigation-menu-cont {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
}
.navigation-menu-box {
	display: flex;
	width: 95%;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 10px 0;
}
.navigation-menu-box:hover {
	background: rgba(242, 242, 242, 0.5);
}
.navigation-menu-box-active {
	background: #f2f2f2;
	border-radius: 5px;
}
.navigation-menu-box-active-underline {
	text-decoration: underline;
}
.navigation-menu-box:focus {
	background: #f2f2f2;
	border-radius: 5px;
}
.border-box {
	margin-top: 20px;
	border-top: 1px solid #c1b190;
	width: 98%;
}
.navigation-menu-box-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
}
.nav-image-box {
	min-width: 60px;
	display: flex;
	align-items: center;
}
.navigation-image {
	padding-left: 15px;
	padding-right: 15px;
	min-width: 50px;
}
.billing-image {
	margin: 0 4px;
}
.navigation-image-settings {
	min-width: 40px;
	padding-left: 5px;
	padding-right: 5px;
}
.navigation-menu-box-settings {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 0;
}
.navigation-menu-box-settings:active {
	background: #f2f2f2;
	border-radius: 5px;
}
.navigation-menu-box-settings:focus {
	background: #f2f2f2;
	border-radius: 5px;
}
.navigation-menu-box-text-settings {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
	margin-left: 10px;
	border: none;
	display: flex;
	align-items: center;
}
.navigation-image-setting {
	padding-right: 15px;
}
.nav-bottom-title-cont {
	display: flex;
	width: 95%;
	margin: auto;
	flex-direction: column;
	justify-content: flex-start;
}
.nav-bottom-title {
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #acacac;
	margin-top: 20px;
	margin-left: 12px;
}
.nav-bottom-link {
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
	margin: 20px 0 20px 12px;
}
@media screen and (max-width: 991px) {
	.main-nav {
		display: inline;
		margin-left: 0;
	}
}
.bg {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #5a5a5a;
	box-shadow: none;
}
.nav-settings-cont {
	display: flex;
	flex-direction: column;
	color: #ffffff !important;
	box-shadow: none;
}
.nav-settings-box {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
	margin: 10px 0;
	box-shadow: none;
}
.accordion-nav {
	width: 98%;
	box-shadow: none;
	box-shadow: none !important;
	margin: 0 !important;
}
.icon-nav-accordion {
	color: #5a5a5a !important;
	box-shadow: none;
}
