.table-title-cont {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  background: #e8edf2;
  padding: 20px 40px;
  width: 100%;
}

.table-subtext {
  font-size: 14px;
  line-height: 16px;
  color: #acacac;
}

.table-title-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #5a5a5a;
}

.table-title-sort {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.main-table-title-cont {
  width: 100%;
  min-height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  border-radius: 5px;
}

.table-main-cont {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #e8edf2;
  width: 100%;
  /* min-height: 95vh; */
  padding: 0 40px;
}

.table-tab-mapping {
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  color: #006dd2;
  border-bottom: 2px solid transparent;
  padding: 10px 10px 24px;
}

.table-tab-mapping:active,
.table-tab-mapping:focus,
.table-tab-mapping:hover {
  color: #5a5a5a;
  border-bottom: 2px solid #5a5a5a;
}

.table-tab-mapping-active {
  font-weight: bold;
  color: #5a5a5a;
  border-bottom: 2px solid #5a5a5a;
}

.table-tab-sync {
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  color: #006dd2;
  border-bottom: 2px solid transparent;
  padding: 10px 10px 20px;
}

.table-tab-sync:active,
.table-tab-sync:focus,
.table-tab-sync:hover {
  color: #5a5a5a;
  border-bottom: 2px solid #5a5a5a;
}

.table-tab-sync-active {
  font-weight: bold;
  color: #5a5a5a;
  border-bottom: 2px solid #5a5a5a;
}

.table-value-search-cont {
  display: flex;
  width: 100%;
  padding: 20px 40px 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.table-value-search-area {
  display: flex;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.table-main-title-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  margin: 0 auto;
  width: 99.7%;
  min-height: 60px;
  max-width: 2560px !important;
}

.table-main-title-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: Roboto;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  background: #fff !important;
  width: 130px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 6px;
  margin-right: 20px;
  border: 1px solid #acacac;
  font-size: 13px;
  line-height: 15px;
  color: #acacac;
  padding-left: 5px;
}

.table-main-title-search-value-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.table-border-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid #e1e1e1;
}

.table-value {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 25px;
  text-transform: uppercase;
  color: #5a5a5a;
  margin-right: 20px;
}

.table-results {
  position: relative;
  width: 100%;
  min-height: 500px;
  padding-left: 0;
}

.table-results-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: #f2f2f2;
  border-radius: 0px;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #5a5a5a !important;
  height: 70px;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 0px;
}

.table-result-box {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e1e1e1;
  height: 80px;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  box-sizing: border-box;
}

.table-result-box div {
  border-top: none;
}

.table-overflow {
  position: relative;
  display: block;
  overflow: auto;
  width: 100%;
  margin: 0 auto;
  max-width: 100vw !important;
  height: 77vh;
  overflow-y: auto;
}

.table-main-title-text {
  font-size: 14px;
  line-height: 30px;
  text-transform: uppercase;
  color: #acacac;
}

.table-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #5a5a5a;
  padding-bottom: 20px;
}

.table-pagination-new button {
  font-size: 13px !important;
  line-height: 30px !important;
  color: #006dd2 !important;
  margin: 0 3px !important;
  border-radius: 5px !important;
  box-shadow: none !important;
}

.table-pagination-new button:focus {
  color: #5a5a5a !important;
  background: #f2f2f2 !important;
}

.table-pagination-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #5a5a5a;
  margin-right: 20px;
}

.table-pagination + p {
  background: #af1b3f;
}

/* .table-result-box:nth-of-type(even) {
	background-color: #f2f2f2;
} */
.table-result-box:hover {
  filter: brightness(95%);
}

.table-result-box:last-child {
  border-radius: 0 0 10px 10px;
  margin-bottom: 10px;
}

.table-search-form {
  width: 300px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #acacac;
  box-sizing: border-box;
  border-radius: 20px;
  margin-right: 20px;
  display: flex;
}

.table-search-form:focus-within {
  border-color: #55718a;
}

.table-search-input {
  width: 80%;
  height: 30px;
  margin: auto;
  background: #ffffff;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #acacac !important;
}

.table-search-input:focus {
  outline: none;
  background: none;
  border: "1px solid #55718A";
}

.table-search-image {
  margin-right: 10px;
}

.table-border-left {
  border-left: 1px solid #e1e1e1;
  height: 100%;
}

.table-modal-cont {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 500px;
  padding: 10px;
}

.table-modal-title-box {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.table-modal-form-title-stock {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  color: #5a5a5a;
}

.table-modal-form-box-stock {
  display: flex;
  flex-direction: column;
  margin-top: -20px;
  align-items: flex-end;
  margin-right: 20px;
}

.table-modal-title-pricing {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}

.table-modal-title-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #5a5a5a;
  margin-top: 5px;
  padding-right: 5px;
}

.table-modal-form-cont {
  box-sizing: border-box;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  text-align: right;
  text-transform: initial;
  color: #5a5a5a;
  width: 100%;
}

.table-modal-form-columns {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;
  width: 100%;
}

.table-modal-form-main-column {
  width: 100%;
}

.table-modal-border {
  width: 100%;
  height: 0px;
  border: 0.5px solid #e1e1e1;
  margin-bottom: 20px;
  margin-top: 10px;
}

.table-modal-form-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.table-modal-input-box {
  width: 100%;
  display: flex;
  text-align: left;
  padding-left: 10px;
  flex-direction: row;
}

.table-modal-cont-box {
  position: relative;
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.table-modal-cont-text {
  font-size: 14px;
  line-height: 21px;
  color: #5a5a5a;
  text-transform: initial;
}

.table-modal-new-price {
  position: absolute;
  width: 31%;
  top: 16%;
  left: 62%;
  right: 15%;
  border: 2px solid #98e9b0;
  margin-left: 22px;
}

.table-modal-new-price-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 20px;
  margin-bottom: 5px;
}

.table-modal-form-title {
  padding-left: 10px;
  margin-right: 20px;
  text-align: right;
  width: 100%;
  max-width: 240px;
}

.table-modal-form-value {
  width: 80px;
  height: 36px;
  margin-bottom: 0px;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.table-modal-form-difference {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 40px;
  color: #acacac;
  text-transform: lowercase;
  padding-left: 10px;
}

/* .remover {
	display: none !important;
}
.adicionar {
	display: block;
}
.table-modal-form-accordion-title:focus .adicionar {
	display: none;
}
.table-modal-form-accordion-title:focus .remover {
	display: inline !important;
} */
.table-modal-form-green {
  width: 100%;
  background: #ddefdd;
  padding: 10px 0;
  margin-top: 10px;
}

.table-modal-form-red {
  background: #f3dfd4;
  padding: 10px 0;
}

.table-modal-form-button-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.table-modal-form-button {
  background: #55718a !important;
  border-radius: 6px !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 19px !important;
  text-transform: uppercase !important;
  color: #ffffff !important;
  padding: 10px 60px !important;
}

.table-modal-form-button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.table-modal-form-button-cancel {
  font-weight: 700;
  font-family: Roboto;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #55718a;
  background-color: #fff;
  border: none;
  box-shadow: none;
}

.table-modal-form-button.add,
.table-modal-form-button.remove {
  width: 20px;
  padding: 2px 12px;
}

.table-modal-difference {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 40px;
  width: 70%;
  filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.25));
  margin-left: 20px;
}

.table-modal-form-accordion-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 5px;
}

.table-modal-form-accordion-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.table-modal-desconto-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 40px;
  text-align: right;
  color: #af1b3f;
  padding: 0 5px;
}

.table-modal-form-box-pvp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  width: 100%;
}

.table-modal-form-accordion-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #af1b3f;
}

.table-modal-difference-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 40px;
  color: #5a5a5a;
}

.table-modal-difference-cont {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-between;
}

.table-modal-difference-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 0 95%;
}

.table-modal-difference-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  color: #5a5a5a;
  display: flex;
  flex-direction: row;
}

.table-modal-difference-text-price {
  color: #af1b3f;
  padding-left: 5px;
  text-decoration: underline;
}

.table-modal-border-value {
  background: #f8f8f8;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 6px;
}

.table-border-right {
  border: 1px solid #e1e1e1;
  height: 100%;
}

.newPriceButton {
  width: 50%;
  justify-content: end;
  padding-right: 10px;
}

.newPrice {
  width: 35%;
  justify-content: end;
}

.newPrice.off,
.dif.off {
  visibility: hidden;
}

.newPrice div.table-modal-form-value {
  margin-right: 20px;
}

.newPrice div.table-modal-form-value input {
  margin-right: -10px;
}

.modal .width50.on {
  opacity: 0.5;
}

.table-modal-button-newprice {
  box-shadow: none;
  border: none;
  color: #006dd2;
  text-transform: unset;
  font-weight: bold;
  background: unset;
  text-decoration: underline;
}

.table-modal-form-box.remove {
  justify-content: flex-end;
  padding-right: 10px;
}

.table-modal-form-button-box button:disabled {
  opacity: 0.5;
  cursor: default;
}

.table-modal-form-box .dif {
  white-space: nowrap;
  margin-right: 5px;
  opacity: 0.5;
  width: 24%;
}

.table-modal-form-box .na {
  padding-right: 14px;
}

.table-modal-form-box .esp {
  visibility: hidden;
}

.table-title-nav-cont {
  min-height: 32px;
}

.table-br {
  display: flex;
  flex-direction: column !important;
  align-items: flex-start !important;
}

.table-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.table-avatar {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  margin-right: 15px;
}

.table-req-modal-cont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.table-req-modal-elems {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 15px;
}

.table-req-status-btn {
  color: #acacac;
  border: 2px solid #acacac;
  border-radius: 6px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  padding: 7px 20px;
}

.table-req-status-btn:hover {
  cursor: pointer;
  color: #9a8e8e;
  border: 2px solid #9a8e8e;
  transform: scale(1.05);
}

.table-req-status-accept {
  color: #00a02d;
  background: #e8f8e2;
  border: 2px solid #00a02d;
  border-radius: 6px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  padding: 7px 20px;
}

.table-req-status-reject {
  color: #c80000;
  background: #ffeded;
  border: 2px solid #c80000;
  border-radius: 6px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  padding: 7px 20px;
}

.table-req-cont-msg {
  text-align: left;
  max-width: 500px;
  margin-bottom: 20px;
}

.table-req-modal-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.table-req-modal-bg {
  background: linear-gradient(to top, #eeeeee 40%, transparent 20%);
}

.req-popup-button {
  background: #55718a;
  border-radius: 6px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;
  color: #ffffff;
  text-align: center;
  padding: 10px 16px;
}

.req-popup-button:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.upload-batch-button {
  background: #4169e1;
  border-radius: 6px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;
  color: #ffffff;
  text-align: center;
  padding: 10px 16px;
}

.upload-batch-button:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.integrated-false {
  color: #9a8e8e;
  opacity: .8;
  font-style: italic;
}

@media screen and (max-width: 1250px) {
  .main-table-title-cont {
    flex-direction: column;
    align-items: center;
  }

  .main-table-title-cont div,
  select {
    margin: 5px 0;
  }

  .table-title-sort {
    align-items: flex-start;
    flex-direction: column-reverse;
    width: 100%;
  }

  .acompan-calendar {
    margin-top: 10px;
  }
}

@media screen and (max-width: 991px) {
  .table-title-nav-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .table-main-cont,
  .table-title-cont {
    padding: 20px 10px 0;
  }

  .table-main-title-select {
    margin-right: 0table-pagination;
  }

  .table-main-title-search-value-cont {
    flex-direction: column;
  }

  .table-main-title-cont {
    flex-direction: column;
  }

  .table-main-title-cont div {
    margin: 5px 0;
  }

  .table-value-search-area {
    flex-direction: column;
  }

  .table-value-search-area div {
    margin: 10px;
  }
}

@media screen and (max-width: 767px) {
  .table-modal-form-columns {
    flex-direction: column;
    align-items: center;
    align-content: center;
  }

  .table-title-cont {
    height: auto;
  }

  .table-title-text {
    margin: 20px 0;
  }

  .table-value-search-cont {
    flex-direction: column;
    padding-right: 0;
  }

  .table-value-search-cont div {
    margin: 10px 0;
  }

  .table-value {
    padding-left: 0;
    margin-bottom: 10px;
  }

  .table-search-form {
    margin-right: 0;
  }

  .table-modal-cont {
    width: 100%;
    overflow: overlay;
    height: 700px;
    padding: 20px 0;
  }

  .table-modal-border {
    margin: 5px 0 20px 0;
  }

  .table-modal-form-box {
    margin-bottom: 5px;
    justify-content: center;
  }

  .table-modal-form-button-box {
    margin-bottom: 10px;
  }

  .table-modal-difference {
    align-items: center;
    width: 100%;
    margin-left: 0;
  }

  .table-modal-difference-box {
    justify-content: center;
    align-items: center;
    flex: 0 1 100%;
  }

  .table-title-sort {
    align-items: center;
  }
}

@media screen and (max-width: 479px) {
  .acompan-dates {
    display: flex !important;
    flex-direction: column !important;
  }

  .acompan-calendar {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .table-modal-border {
    width: auto;
  }
}

.table-billing-height {
  height: 60vh !important;
}

.table-single-billing-height {
  height: 83vh !important;
}
.table-gestao-height {
  height: 69vh;
  margin-bottom: 0px;
}

.table-pim-main-height {
  height: 78vh !important;
  margin-bottom: 20px;
}

.table-acompan-height {
  height: 79vh !important;
  margin-bottom: 20px;
}

.table-marketplace-height {
  height: 80vh !important;
  margin-bottom: 20px;
}

.table-single-marketplace-height {
  height: 69vh !important;
  margin-bottom: 20px;
}

.table-vendor-management-height {
  height: 80vh !important;
  margin-bottom: 20px;
}

.table-freight-height {
  height: 81vh !important;
  margin-bottom: 20px;
}

.table-tax-height {
  height: 81vh !important;
  margin-bottom: 20px;
}

.table-vendors-height {
  height: 72vh !important;
  margin-bottom: 20px;
}

.table-sync-height {
  height: 72vh !important;
  margin-bottom: 20px;
}

.table-pass-height {
  height: 72vh !important;
  margin-bottom: 20px;
}

.table-user-height {
  height: 72vh !important;
  margin-bottom: 20px;
}

.table-review-height {
  height: 70vh !important;
  margin-bottom: 20px;
}

.table-country-height {
  height: 80vh !important;
  margin-bottom: 20px;
}

.table-file-height {
  height: 81vh !important;
  margin-bottom: 20px;
}
/* @media screen and (max-width: 1480px) {
	.table-billing-height {
		height: 35vh !important;
	}

	.table-single-billing-height {
		height: 72vh !important;
	}

	.table-gestao-height {
		height: 72vh !important;
		margin-bottom: 10px;
	}

	.table-pim-main-height {
		height: 63vh !important;
	}

	.table-acompan-height {
		height: 65vh !important;
	}

	.table-marketplace-height {
		height: 68vh !important;
	}

	.table-single-marketplace-height {
		height: 49vh !important;
	}

	.table-vendor-management-height {
		height: 68vh !important;
	}

	.table-freight-height {
		height: 69vh !important;
	}

	.table-tax-height {
		height: 69vh !important;
	}

	.table-vendors-height {
		height: 55vh !important;
	}

	.table-sync-height {
		height: 55vh !important;
	}

	.table-pass-height {
		height: 60vh !important;
	}

	.table-user-height {
		height: 55vh !important;
	}

	.table-review-height {
		height: 50vh !important;
	}

	.table-country-height {
		height: 68vh !important;
	}

	.table-file-height {
		height: 69vh !important;
	}
} */
@media screen and (max-height: 799px) {
  .table-gestao-height {
    height: 65vh !important;
    margin-bottom: 0px;
  }
}

/* @media screen and (max-width: 1950px) {
	.table-billing-height {
		height: 48vh !important;
	}

	.table-single-billing-height {
		height: 78vh !important;
	}

	.table-gestao-height {
		height: 73vh !important;
		margin-bottom: 5px;
	}

	.table-pim-main-height {
		height: 71vh !important;
	}

	.table-acompan-height {
		height: 72vh !important;
	}

	.table-marketplace-height {
		height: 74vh !important;
	}

	.table-single-marketplace-height {
		height: 59vh !important;
	}

	.table-vendor-management-height {
		height: 74vh !important;
	}

	.table-freight-height {
		height: 76vh !important;
	}

	.table-tax-height {
		height: 76vh !important;
	}

	.table-vendors-height {
		height: 64vh !important;
	}

	.table-sync-height {
		height: 64vh !important;
	}

	.table-pass-height {
		height: 65vh !important;
	}

	.table-user-height {
		height: 64vh !important;
	}

	.table-review-height {
		height: 60vh !important;
	}

	.table-country-height {
		height: 74vh !important;
	}

	.table-file-height {
		height: 75vh !important;
	}
} */
