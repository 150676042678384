.contrato-bg {
	z-index: 1100;
	width: 100%;
	height: 100%;
	background: #f2f2f2 !important;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	margin-left: auto;
	margin-right: auto;
	min-height: 100vh;
}
.payment-header {
	background: #ffffff;
	height: 68px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
.payment-cont {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;
	max-width: 90%;
	width: 100%;
	margin-top: 40px;
	padding-bottom: 40px;
}
.payment-box {
	flex: 0 1 28%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
}
.payment-text,
.payment-form-text {
	font-family: "Roboto";
	font-style: normal;
	font-size: 14px !important;
	color: #5a5a5a;
}
.payment-text {
	text-transform: uppercase;
	font-weight: 700 !important;
	line-height: 16px !important;
}
.payment-form-text {
	font-weight: 400 !important;
	line-height: 21px !important;
}
.confirm-btn {
	background: #55718a;
	border-radius: 6px;
	width: 100%;
	padding: 12px 0;
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	color: #ffffff;
	text-transform: uppercase !important;
}
.payment-container {
	background: #ffffff;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 30px;
	width: 100%;
	margin-top: 15px;
}
.payment-input {
	width: 100%;
	background: #f2f2f2;
	border: 1px solid #e1e1e1;
	border-radius: 4px;
	height: 40px;
	margin-top: 8px;
	padding-left: 10px;
}
.payment-border {
	margin: 20px 0;
	width: 100%;
	border-bottom: 1px solid #e1e1e1;
}
.payment-cost {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 21px;
	text-align: right;
	color: #5a5a5a;
}
.payment-plans-swither {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.payment-plans-swither-cont {
	width: 180px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #5a5a5a;
	border-radius: 20px;
}
.payment-plans-swither-text-cont {
	width: 50%;
	display: flex;
	justify-content: center;
	cursor: pointer;
}
.payment-plans-swither-text {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 30px;
	color: #5a5a5a;
	width: 100%;
	display: flex;
	justify-content: center;
}
.payment-plans-swither-text-active div {
	display: flex;
	justify-content: center;
	background: #5a5a5a;
	border-radius: 20px;
	color: #ffffff;
	margin: 2px;
}
.payment-plans-options-cont {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 20px;
}
.payment-plans-option {
	display: flex;
	flex-direction: row;
	margin-bottom: 20px;
	align-items: flex-start;
}
.payment-plans-plan {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}
.payment-plans-plan-text {
	font-size: 16px;
	font-weight: 700;
	line-height: 21px;
	color: #5a5a5a;
}
.payment-plans-plan-subs-text {
	font-size: 12px;
	font-weight: 600;
	line-height: 22px;
	color: #909090;
}
.payment-plans-plan-price {
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	color: #5a5a5a;
}
.payment-plans-plan-period {
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	color: #5a5a5a;
}
.payment-plans-plan-actual-price {
	font-size: 12px;
	font-weight: 400;
	line-height: 22px;
	color: #909090;
}
.payment-plans-plan-old-price {
	font-size: 12px;
	font-weight: 400;
	line-height: 22px;
	text-decoration: line-through;
	color: #909090;
}
.payment-plans-subscription-text {
	font-size: 12px;
	font-weight: 400;
	line-height: 19px;
	color: #5a5a5a;
}
.pricing-orange {
	color: #f4733c;
}
.orange-text span {
	color: #f4733c;
}
.coupon-input {
	background: #f2f2f2;
	border: 1px solid #e1e1e1;
	border-radius: 4px;
	padding: 16px;
}
.coupon-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #acacac;
	color: #ffffff;
	border-radius: 6px;
	border: 1px solid #acacac;
	text-align: center;
	margin-left: -16px;
	height: 100%;
}
.coupon-text,
.coupon-added-text {
	font-size: 14px;
	font-weight: 700;
	line-height: 16px;
	color: #299c00;
}
.coupon-text {
	font-weight: 700;
}
.coupon-added-text {
	font-weight: 400;
}
.coupon-remove-text {
	font-size: 12px;
	font-weight: 700;
	line-height: 22px;
	color: #55718a;
}
.coupon-added-plan {
	width: 90%;
	border: 1px solid #299c00;
	padding: 15px;
}
.coupon-plan-text,
.coupon-plan-text span {
	color: #f1692e;
}
.loading-area {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
	text-align: center;
	color: #545454;
}

@media screen and (max-width: 991px) {
	.payment-cont {
		justify-content: center;
	}
	.payment-box {
		flex: 0 1 100%;
	}
	.payment-container {
		margin-bottom: 20px;
	}
	.payment-text {
		margin-left: 10px;
	}
	.payment-cont {
		max-width: 600px;
	}
	.payment-container {
		max-width: 540px;
	}
}
@media screen and (max-width: 769px) {
	.payment-cont {
		max-width: 480px;
	}
	.payment-container {
		max-width: 420px;
	}
}
@media screen and (max-width: 496px) {
	.payment-cont {
		max-width: 280px;
	}
	.payment-container {
		max-width: 220px;
	}
}
