.root {
	display: flex;
}

.drawer-header {
	display: "flex";
	align-items: "center";
	padding: 30px;
	justify-content: "flex-end";
}

.content {
	flex-grow: 1;
	transition-property: margin-left;
	transition-duration: 0.5s;
	margin-left: -240px;
}

.content-shift {
	flex-grow: 1;
	transition-property: margin-left;
	transition-duration: 0.5s;
	margin-left: 0px;
}
