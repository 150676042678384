.reviews-title-text {
  margin-left: 20px;
  margin-right: 12px;
  font-family: Roboto;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: #5a5a5a;
  border-radius: 6px;
}
.reviews-title-margin {
  margin-bottom: 20px;
}
.reviews-title-sort {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.reviews-title-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.reviews-sku {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 8%;
  padding-right: 5px;
  text-align: center;
  width: 140px;
  height: 100%;
}
.reviews-origin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 10%;
  padding-right: 5px;
  text-align: center;
  width: 160px;
  height: 100%;
}
.reviews-country {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 12%;
  padding-right: 5px;
  text-align: center;
  width: 180px;
  height: 100%;
}
.reviews-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 20%;
  padding-right: 5px;
  text-align: center;
  width: 240px;
  height: 100%;
}
.reviews-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 14%;
  padding-right: 5px;
  text-align: center;
  width: 180px;
  height: 100%;
}
.reviews-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 14%;
  padding-right: 5px;
  text-align: center;
  width: 180px;
  height: 100%;
}
.reviews-rating {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 12%;
  padding-right: 5px;
  text-align: center;
  width: 160px;
  height: 100%;
}
.reviews-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 14%;
  padding-right: 5px;
  text-align: center;
  width: 180px;
  height: 100%;
}
.review-button {
  padding: 10px 20px;
  margin: 0 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #5a5a5a;
  border-radius: 6px;
}
.review-tables {
  background: #f2f2f2 !important;
  box-shadow: none !important;
}
@media screen and (max-width: 991px) {
  .reviews-title-sort {
    flex-direction: column;
  }
  .reviews-title-box {
    margin: 10px 0;
  }
}

/* New Reviews */
.rew-img,
.rew-minc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80px;
  flex: 0 1 8%;
}
.rew-mins,
.rew-meds,
.rew-lgs,
.rew-biggest,
.rew-action {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.rew-mins {
  width: 100px;
  flex: 0 1 12%;
}
.rew-meds {
  width: 120px;
  flex: 0 1 16%;
}
.rew-lgs {
  width: 200px;
  flex: 0 1 20%;
}
.rew-biggest {
  width: 500px;
  flex: 0 1 45%;
}
.rew-action {
  width: 100px;
  flex: 0 1 12%;
}
/* End New Reviews */
