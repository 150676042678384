.menu-item {
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.notification-title {
  margin: 0 0 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #5a5a5a;
}
.menu-item-active {
  background-color: rgb(255, 255, 255);
}
.notification-image-box {
  width: 50px;
  height: 50px;
  background: #f2eddc;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-item-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
}

.note-title {
  font-size: 14px;
  line-height: 16px;
  color: #5a5a5a;
  padding: 5px;
  margin-right: 60px;
}

.note-date {
  font-size: 11px;
  line-height: 13px;
  color: #acacac;
  padding: 5px;
}

.menu-item-button {
  margin-top: 0;
  margin-bottom: 0;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  border-top: 1px solid #e1e1e1;
}

.menu-item-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 16px 5px 16px;
}

.read-all-button {
  background-color: #314455;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
}

.open-all-button {
  background-color: #f1692e;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
}

.read-all-button:hover .open-all-button:hover {
  cursor: pointer;
  transform: scale(1.02);
}
.notification-border {
  border-top: 1px solid #e1e1e1;
}
