.table-main-cont {
  align-items: normal;
}

h4 {
  padding: 0 0 15px 0;
}

.selectdiv {
  position: relative;
  min-width: 150px;
  margin: 10px 20px 10px 0;
}

/*To remove button from IE11, thank you Matt */
select::-ms-expand {
  display: none;
}

.selectdiv:after {
  content: ">";
  font: 18px "Consolas", monospace;
  color: #fff;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 11px;
  /*Adjust for position however you want*/

  top: 18px;
  padding: 0 0 2px;

  position: absolute;
  pointer-events: none;
}

.selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add some styling */

  display: block;
  max-width: 350px;
  height: 50px;
  float: right;
  padding: 0px 20px;
  color: #5a5a5a;
  border: none;
  border-radius: 0;
}
.int-select {
  min-width: 200px;
  color: #000 !important;
}
.int-select li {
  padding: 0;
  margin: 0;
}
.int-filter-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}
.int-dropdown {
  display: flex;
  flex-direction: row;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #ffffff;
  background: #55718a;
  border-radius: 5px;
  padding: 10px 20px;
}
.int-main-acc {
  margin: 0 !important;
  text-align: start;
  box-shadow: none !important;
}
.int-accordion {
  display: flex;
  flex-direction: row-reverse;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #5a5a5a;
  min-height: 40px !important;
  box-shadow: none !important;
  padding: 5px 0 !important;
  border-top: 1px solid #e1e1e1 !important;
  border-bottom: 1px solid #e1e1e1 !important;
}
.int-accordion div {
  margin: 5px 0 !important;
}
.int-option-title {
  font-weight: 700;
  font-size: 21px;
  line-height: 25px;
  color: #161600;
  text-align: start;
  padding: 10px;
}
.int-option {
  display: flex !important;
  width: 100% !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #006dd2 !important;
  padding: 5px 0 5px 15px !important;
}
.int-option:first-child {
  padding-top: 10px !important;
}
.int-option:last-child {
  padding-bottom: 10px !important;
}
.int-status-title {
  margin-left: 20px;
  padding: 0 !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #5a5a5a;
}
.int-image {
  max-width: 120px;
  max-height: 100px;
}
.int-card-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
.int-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #5A5A5A;  
}
.int-subtext {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ACACAC;  
}
.int-card-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 5px 0;
}
.int-blue-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 42px;
  line-height: 49px;
  display: flex;
  align-items: center;
  color: #006DD2;  
}
.int-red-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 42px;
  line-height: 49px;
  display: flex;
  align-items: center;
  color: #EA596E;
}
.int-sub-image {
  margin: 0 20px;
  display: flex;
  flex-direction: row;
}
.int-prod-grey-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ACACAC;
}
.int-prod-sku-ean {
  padding-right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 200px;
  padding-left: 10px;
}
.int-prod-title {
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  width: 350px;
  padding-left: 10px;
}
.int-prod-item {
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 350px;
  padding-left: 10px;
}
.int-stock {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #5A5A5A;
  width: 100% !important;
  margin-top: 10px;
  padding: 6px;
}
.int-prod-green, .int-prod-grey, .int-prod-red {
  z-index: 10;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.int-prod-green {
  color: #299C00;
}
.int-prod-green:hover {
  background: #299C00;
  color: #fff !important;
}
.int-prod-grey {
  color: #5A5A5A;
}
.int-prod-grey:hover {
  background: #5A5A5A;
  color: #fff !important;
}
.int-prod-red {
  color: #EA596E;
}
.int-prod-red:hover {
  background: #EA596E;
  color: #fff !important;
}
.int-prod-na {
  color: #ACACAC;
  background: #FFFFFF;
  border: 1px solid #E1E1E1;
  padding: 6px;
}
.int-prod-modal-title-cont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.int-modal-title-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #5A5A5A;
}
.int-modal-image {
  width: 100px !important;
  height: 100px !important;
  margin-right: 20px;
}
.int-prod-modal-title-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #5A5A5A;
  margin-bottom: 17px;
}
.int-modal-buybox-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 30px;
  color: #EA596E;
  margin-right: 10px;
}
.int-modal-price-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 30px;
  color: #5A5A5A;  
  margin-left: 10px;
}
.int-modal-table-position {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  align-items: center;
  justify-content: flex-end;
  flex: 0 1 15%;
  max-width: 70px;
  text-align: right;
  height: 100%;
}
.int-modal-table-price {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  align-items: center;
  justify-content: flex-end;
  flex: 0 1 15%;
  max-width: 70px;
  text-align: right;
  height: 100%;
}
.int-modal-table-seller {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  align-items: center;
  justify-content: flex-start;
  flex: 0 1 50%;
  max-width: 200px;
  height: 100%;
}
.int-modal-table-page {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  align-items: center;
  justify-content: flex-start;
  flex: 0 1 25%;
  max-width: 200px;
  height: 100%;
}
.int-modal-hover:hover {
  background: #FFEA9F;
}
.int-tab-sync {
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #006DD2;
	border-bottom: 2px solid transparent;
	padding: 0 10px 10px;
}
.int-tab-sync:active,
.int-tab-sync:focus,
.int-tab-sync:hover {
	color: #5a5a5a;
	border-bottom: 2px solid #5a5a5a;
}
.int-tab-sync-active {
  font-weight: bold;
	color: #5a5a5a;
	border-bottom: 2px solid #5a5a5a;
}
.int-set-match-main-cont {
  background: #fff;
  width: 99.7%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.int-set-match-logo {
  border: 1px solid #e1e1e1;
  margin: 20px 0;
}
.int-set-match-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px 20px;
}
.int-set-match-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.int-set-match-box:last-child {
  margin-bottom: 40px;
}
.int-set-match-arrow-box {
  width: 50px !important;
  height: 24px !important;
  margin: 0 15px;
}
.int-product-input,
.int-product-select {
	background: #F2F2F2;
	border: 1px solid #E1E1E1;
	border-radius: 6px;
	width: 100%;
	height: 40px;
	padding-left: 10px;
}
.int-product-select {
  background: #Fff;
}
.int-product-select:active {
font-weight: bold;
}
.int-product-input::placeholder {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	color: #5A5A5A;
}
.int-set-list-title-buttons-cont {
  background: #FFFFFF;
  border: 1px solid #ACACAC;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.int-set-list-title-button {
  border-radius: 16px;
  padding: 0 10px;
  cursor: pointer;
  color: #5A5A5A;
  background: #FFFFFF;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 30px;
}
.int-set-list-title-button:hover, .int-set-list-title-button:active, .int-set-list-title-button:focus {
  color: #fff;
  background: #5A5A5A;
}
.int-set-list-table-seller {
  width: 100%;
  display: flex;
}
.int-set-list-table-platform {
  padding-right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  flex: 0 1 30%;
  padding-left: 10px;
}
.int-set-list-table-active {
  padding-right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  flex: 0 1 15%;
  padding-left: 10px;
}
.int-set-list-table-inactive {
  padding-right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 0 1 15%;
  padding-left: 10px;
}
.int-set-list-table-rounds {
  padding-right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 0 1 8%;
  padding-left: 10px;
}
.int-set-list-table-update {
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 0 1 20%;
  padding-left: 10px;
}
.int-set-actions-modal {
  background: #FFFFFF;
  border: 1px solid #006DD2;
  border-radius: 5px;
  padding: 5px 15px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #006DD2;  
}
.int-nav-link {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #006DD2;
  margin: 0 10px;
  min-width: 80px;
}
.int-set-list-active-prod-modal-switch {
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 0 1 40px;
  padding-left: 10px;
}
.int-set-list-active-prod-modal-image {
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 0 1 80px;
}
.int-set-list-active-prod-modal-sku {
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  flex: 0 1 150px;
}
.int-set-list-active-prod-modal-id {
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  flex: 0 1 150px;
}
.int-set-list-active-prod-modal-title {
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  flex: 0 1 350px;
}
.int-set-list-active-prod-modal-url {
  padding-right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  flex: 0 1 300px;
  padding-left: 5px;
}
.int-set-modal-cancel {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #55718A;
  padding: 10px 20px;
  box-shadow: none;
  border: none;
  background: transparent;
}
.int-set-modal-done {
  background: #55718A;
  border-radius: 6px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #FFFFFF;  
  padding: 10px 20px;
}
.int-set-list-modal-setup-tab {
  background: #F2F2F2 !important;
  border: 2px solid #E1E1E1 !important;
  border-radius: 6px !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #ACACAC !important;
  padding: 5px 10px !important;
  box-shadow: none !important;
  margin: 0 8px !important;
}
.int-xls-text {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #ACACAC;  
}
.int-input {
  background: #FFFFFF;
  border: 1px solid #ACACAC;
  border-radius: 6px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #5A5A5A;  
  width: 60px;
  min-height: 40px;
  margin-right: 10px;
  padding: 5px;
  text-align: right;
}
.int-set-single-day-inactive, .int-set-single-day-active {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  margin: 0 10px;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  text-transform: uppercase;
  cursor: pointer;
}
.int-set-single-day-inactive {
  background: #F2F2F2;
  color: #ACACAC;
}
.int-set-single-day-active {
  color: #FFFFFF;
  background: #006DD2;
}
