.pass-site {
  display: flex;
  align-items: center;
  flex: 0 25%;
  padding: 0 10px;
  max-width: 150px;
  height: 100%;
}
.pass-country {
  display: flex;
  align-items: center;
  flex: 0 1 15%;
  padding: 0 10px;
  max-width: 450px;
  height: 100%;
}
.pass-name {
  display: flex;
  align-items: center;
  flex: 0 1 25%;
  padding: 0 10px;
  max-width: 450px;
  height: 100%;
}
.pass-password {
  display: flex;
  align-items: center;
  flex: 0 1 20%;
  padding: 0 10px;
  max-width: 450px;
  height: 100%;
  justify-content: space-between;
}
.pass-act {
  display: flex;
  align-items: center;
  flex: 0 1 15%;
  padding: 0 10px;
  max-width: 150px;
  height: 100%;
  width: 100%;
  justify-content: center;
}
.pass-mfa {
  display: flex;
  align-items: center;
  flex: 0 1 15%;
  padding: 0 10px;
  max-width: 250px;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
}

.totp-token-remaining-seconds-pie {
  display: block;
  width: 22px;
  height: 22px;
  margin-left: auto;
  transform: rotate(-90deg);
  border-radius: 50%;
  stroke: #5892ee;
}

.totp-token-remaining-seconds-pie > circle {
  transition: stroke-dashoffset 1s linear;
  stroke: #5892ee;
  stroke-width: 100%;
  stroke-dasharray: 1;
  stroke-dashoffset: -1;
  fill: none;
}
