.jobs-run,
.jobs-platform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.jobs-title,
.jobs-desc,
.jobs-freequency,
.jobs-status,
.jobs-action {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5px;
}

.jobs-run {
  width: 80px;
  flex: 0 1 5%;
}

.jobs-title {
  width: 160px;
  flex: 0 1 14%;
}

.jobs-platform {
  width: 80px;
  flex: 0 1 8%;
}

.jobs-desc {
  width: 300px;
  flex: 0 1 35%;
}

.jobs-freequency {
  width: 150px;
  flex: 0 1 15%;
}

.jobs-status {
  width: 100px;
  flex: 0 1 12%;
}

.jobs-action {
  justify-content: space-between;
  width: 160px;
  flex: 0 1 14%;
  padding-right: 20px;
}

.jobs-dott {
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.jobs-green {
  background: #85d671;
}

.jobs-red {
  background: #ff8383;
}

/* Report */
.report-text {
  font-size: 14px;
  line-height: 40px;
}

.report-func-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  margin-right: 10px;
}

.report-ok {
  color: #00ba13;
}

.report-error {
  color: #e80000;
}

/* End Report */
.tax-modal-input::placeholder {
  color: #aaa;
}

.tax-modal-input[type="checkbox"] {
  height: auto;
}
