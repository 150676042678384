.requests-container {
	height: 100vh;
	min-width: 80vw;
	min-height: 100vh;
	width: 80vw;
	margin: 0 auto;
}

.requests-title {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	margin-top: 15px;
	margin-bottom: 10px;
}

.requests-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	margin-bottom: 10px;
}

.requests-header-admin {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	padding: 10px;
	margin-bottom: 10px;
}

.requests-select {
	padding: 10px;
	border-radius: 5px;
	width: 170px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
	margin-left: 7px;
	background: #e5e5e5;
}

.requests-admin-input {
	margin-bottom: 10px;
}

.admin-status-select {
	padding: 3px;
	border-radius: 5px;
	width: 170px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
	margin-left: 7px;
	background: #ffffff;
}

.requests-new-button {
	background-color: #920023;
	color: #fff;
	height: 40px;
	font-size: 16px;
	border-radius: 5px;
}

.requests-new-button:hover {
	cursor: pointer;
	transform: scale(1.02);
}

.requests-line {
	border-top: 2px solid rgba(0, 0, 0, 0.1);
	margin-bottom: 10px;
}

.requests-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
}

.requests-content-cards {
	width: 100%;
}

.requests-content-card {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 80px;
	background-color: #920023;
	padding: 20px 40px;
	border-radius: 10px;
	margin-bottom: 10px;
}

.requests-content-card:hover {
	cursor: pointer;
	background-color: #b0012a;
}

.requests-content-card:hover .card-status {
	opacity: 0;
}

.card-cancel {
	opacity: 0;
	position: absolute;
}

.requests-content-card:hover .card-cancel {
	opacity: 1;
}

.card-cancel-button {
	background-color: #ffffff;
	color: #920023;
}

.card-cancel-button:hover {
	cursor: pointer;
	transform: scale(1.02);
}

.content-card-update {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	color: #fff;
}

.card-update-title {
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 7px;
}

.card-update-info {
	font-size: 12px;
	font-weight: normal;
}

.content-card-status {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;
	color: #fff;
	overflow: hidden;
}

.card-status {
	font-size: 20px;
	font-style: italic;
	margin-bottom: 7px;
	position: relative;
}

.card-status-date {
	font-size: 12px;
	font-weight: normal;
}

.newReq-container {
	height: 40vh;
	min-width: 50vw;
	min-height: 40vh;
	width: 50vw;
	margin: 0 auto;
	padding: 5px;
}

.newReq-title {
	padding: 5px;
	margin-bottom: 10px;
	color: #000;
	font-weight: bold;
	font-size: 18px;
}

.newReq-content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	padding: 5px;
}

.content-select-request {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	color: #000;
	font-size: 16px;
	font-weight: normal;
	margin-bottom: 20px;
}

.content-title {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	color: #000;
	font-size: 16px;
	font-weight: normal;
	margin-bottom: 20px;
}

.content-title-input {
	padding: 10px;
	width: 600px;
}

.content-content {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	color: #000;
	font-size: 16px;
	font-weight: normal;
	margin-bottom: 20px;
}

.content-content-input {
	padding: 10px;
	width: 600px;
}

.request-send-button {
	background-color: #920023;
	color: #fff;
	height: 40px;
	font-size: 16px;
	border-radius: 5px;
	margin: 20px auto;
}

.request-send-button:hover {
	cursor: pointer;
	transform: scale(1.02);
}

.requests-message {
	margin-top: 60px;
	width: 100%;
	text-align: center;
	font-weight: 500;
	font-size: 22px;
}
@media screen and (max-width: 991px) {
	.requests-content-card {
		min-height: 200px;
		flex-direction: column;
	}
}

@media screen and (max-width: 769px) {
	.requests-content-card {
		min-height: 300px;
	}
	.requests-header {
		flex-direction: column;
	}
	.requests-header div {
		margin: 5px 0;
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: flex-end;
	}
	.requests-admin-input {
		flex-direction: column !important;
	}
	.requests-admin-input div {
		margin-top: 10px;
	}
}
