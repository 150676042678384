.date-select-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
}

.date-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: Roboto;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #5a5a5a;
  background: #fff !important;
  min-width: 150px !important;
  height: 40px;
  border: 1px solid #5a5a5a;
  box-sizing: border-box;
  border-radius: 6px;
  margin-right: 12px;
  padding-left: 5px;
}
.date-select:active,
.date-select:focus {
  font-weight: 500;
  background: #5a5a5a;
}

@media screen and (max-width: 991px) {
  .date-select-container {
    flex-direction: column;
  }
  .date-select {
    margin-right: 0;
  }
}

@media screen and (max-width: 769px) {
  .date-select-container {
    padding-right: 0;
  }
}
