.main-area {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	margin: 50px auto;
}

.section {
	display: flex;
	flex-direction: column;
	padding: 20px 30px;
	width: 100%;
}

.translate-into {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	margin: px;
}

.section-label {
	margin-bottom: 10px;
}

.input-area {
	width: 100% !important;
	padding: 10px;
}

.translate-button {
	margin: 20px auto;
	padding-left: 20px;
}
