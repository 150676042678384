.pricing-id,
.pricing-title,
.pricing-status,
.pricing-sku,
.pricing-creator {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.pricing-id {
	min-width: 120px;
	justify-content: center;
}
.pricing-title {
	min-width: 150px;
	flex: 0 1 20%;
	justify-content: flex-start;
}
.pricing-status {
	min-width: 100px;
	flex: 0 1 10%;
	justify-content: center;
}
.pricing-period {
	min-width: 200px;
	flex: 0 1 15%;
}
.pricing-sku {
	min-width: 100px;
	flex: 0 1 10%;
	justify-content: center;
}
.pricing-result {
	min-width: 200px;
	flex: 0 1 15%;
}
.pricing-creator {
	min-width: 150px;
	flex: 0 1 15%;
	justify-content: flex-start;
	margin-left: 50px;
}
.pricing-scheduled,
.pricing-active,
.pricing-expired,
.pricing-aborted {
	border: 1px solid #e1e1e1;
	box-sizing: border-box;
	border-radius: 5px;
	padding: 5px 10px;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
}
.pricing-scheduled {
	color: #5a5a5a;
	background: #fff5d3;
}
.pricing-active {
	color: #12ac9d;
	background: #e1ffe6;
}
.pricing-expired {
	color: #acacac;
	background: #ffffff;
}
.pricing-aborted {
	color: #ea596e;
	background: #ffeaee;
}
.pricing-line {
	display: flex;
	width: 80%;
	background: #bebebe;
	position: relative;
	border-radius: 3px;
	height: 7px;
}
.pricing-line-active {
	width: 100%;
	height: 100%;
	border-radius: 3px;
}
.pricing-line-over {
	margin: 1.5px 0px 0px 1px;
	height: 3px;
	background: #ffff00;
	z-index: 9;
}
.pricing-line-aborted {
	background: #f4a1ae;
}
