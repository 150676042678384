.billing-modal-cont {
	max-height: 700px;
	max-width: 400px;
	overflow-y: auto;
	padding: 10px;
}
.billing-medium {
	padding-right: 5px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex: 0 12%;
	width: 120px;
	height: 100%;
}
.billing-small {
	padding-right: 5px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex: 0 8%;
	width: 90px;
	height: 100%;
}
.billing-text {
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
}
.billing-cost {
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
	width: 88%;
	display: flex;
	justify-content: center;
}
.billing-aberto {
	background: #fff5d3 !important;
	border: 1px solid #e1e1e1 !important;
	box-sizing: border-box !important;
	border-radius: 5px !important;
	color: #ed0000 !important;
	font-weight: 500 !important;
	padding: 5px 10px !important;
}
.billing-fechado {
	background: #ffffff !important;
	border: 1px solid #e1e1e1 !important;
	box-sizing: border-box !important;
	border-radius: 5px !important;
	font-weight: 500 !important;
	padding: 5px 10px !important;
}
.billing-redtext {
	color: #ed0000 !important;
}
.billing-underline {
	text-decoration: underline !important;
	text-decoration-color: #5a5a5a !important;
}
.billing-largetext {
	font-size: 18px;
	line-height: 21px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #5a5a5a;
}
.billing-modal-cont {
	max-height: 700px;
	width: 740px;
	overflow-y: auto;
	padding: 10px;
}
.billing-modal-title-cont {
	padding-top: 10px;
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.billing-modal-text {
	font-size: 14px;
	line-height: 21px;
	color: #5a5a5a;
	text-transform: initial;
}
.billing-modal-subtext {
	text-transform: lowercase;
	padding-left: 10px;
	color: #acacac;
}
.billing-modal-box {
	position: relative;
	height: 25px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.billing-input-box {
	width: 100%;
	display: flex;
	text-align: left;
	padding-left: 30px;
	flex-direction: row;
}
.billing-modal-invoice-cont {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}
.billing-modal-invoice-box {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
}
.billing-modal-medium {
	display: flex;
	align-items: center;
	flex: 0 12%;
	max-width: 100px;
	height: 100%;
	padding-right: 10px;
	font-size: 14px;
	line-height: 30px;
}
.billing-modal-xmedium {
	display: flex;
	align-items: center;
	flex: 0 20%;
	max-width: 150px;
	height: 100%;
	padding-right: 10px;
	font-size: 14px;
	line-height: 30px;
}
.billing-modal-large {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex: 0 52%;
	max-width: 400px;
	height: 100%;
	padding-right: 10px;
	font-size: 14px;
	line-height: 30px;
}
.billing-modal-result-box {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 20px 0;
}
.billing-modal-table-line {
	display: flex;
	width: 100%;
	background: #e1e1e1;
	position: relative;
	border-radius: 6px;
}
.billing-modal-table-line-active {
	display: flex;
	width: 30%;
	background: #5cbe7d;
	border-radius: 6px;
}
.billing-modal-table-line-box {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
	padding-top: 10px;
}
.billing-graph-cont {
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
}
.billing-graph-cont + div:first-child {
	margin-right: 20px;
}
.billing-graph-cont > div:last-child {
	margin-left: 20px;
}
.billing-graph-box {
	width: 100%;
	min-height: 330px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	background: #ffffff;
	border: 1px solid #e1e1e1;
	box-sizing: border-box;
	/* box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.03); */
	border-radius: 12px;
	margin: 10px 0;
	padding: 20px;
}
.billing-large-text {
	font-size: 32px;
	line-height: 37px;
	text-transform: uppercase;
	font-weight: bold;
}
.billing-mid-text {
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;
	text-transform: uppercase;
	color: #acacac;
}
.billing-perf-total-cont {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}
.billing-ball {
	width: 8px;
	height: 8px;
	border-radius: 100%;
}
.billing-progress-cont {
	margin: 5px 0;
	overflow: auto;
	width: 100%;
	height: 140px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}
.billing-progress-box {
	display: flex;
	padding: 5px 10px;
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.billing-width-progress {
	min-width: 120px;
	display: flex;
}
.billing-table-line {
	display: flex;
	width: 100%;
	background: #bebebe;
	position: relative;
	border-radius: 3px;
}
.billing-table-line-active {
	display: flex;
	background: #5cbe7d;
	border-radius: 3px;
}
.billing-table-line-text {
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	font-size: 12px;
	line-height: 14px;
	text-align: center;
	text-transform: uppercase;
	color: #ffffff;
}

/* Quantidade */
.billing-quan-box {
	display: flex;
	padding: 5px 20px;
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}
.billing-quan-cont {
	display: flex;
	padding: 20px;
	width: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.billing-circular {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}
.billing-circular-box {
	width: 100%;
	display: flex;
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	right: 0;
	left: 0;
	top: 0;
	bottom: 0;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.billing-modal-border {
	width: 100%;
	height: 0px;
	border: 0.5px solid #e1e1e1;
	margin-top: 10px;
}

.table-result-box.billing-text {
  width: 100%;
  margin: 0;
  /* border: 0; */
}

@media screen and (max-width: 1300px) {
	.billing-graph-cont {
		flex-direction: column;
	}
	.billing-graph-cont div:first-child {
		margin-right: 0;
	}
	.billing-graph-cont div:last-child {
		margin-left: 0;
	}
	.billing-graph-box {
		padding: 10px;
		margin: 20px 0;
	}
	.billing-progress-box {
		padding: 5px;
	}
	.billing-width-progress {
		min-width: 60px;
	}
}
@media screen and (max-width: 769px) {
	.billing-modal-cont {
		width: 100%;
	}
}
@media screen and (max-width: 496px) {
	.billing-quan-box {
		flex-direction: column;
	}
}

/* Payment Batch Start */
.paybatch-id, .paybatch-desc, .paybatch-amount-bil, .paybatch-products, .paybatch-amount, .paybatch-date, .paybatch-status {
	display: flex;
}
.paybatch-id, .paybatch-desc, .paybatch-amount-bil, .paybatch-products, .paybatch-amount, .paybatch-status {
	flex-direction: row;
	align-items: center;
}
.paybatch-date {
	flex-direction: column;
	justify-content: center;
	flex: 0 1 8%;
	width: 120px;
	align-items: flex-end;
	text-align: end;
}
.paybatch-id {
	flex: 0 1 10%;
	width: 120px;
	margin-left: 20px;
	justify-content: flex-start;
}
.paybatch-desc {
	flex: 0 1 30%;
	width: 300px;
	justify-content: flex-start;
}
.paybatch-amount-bil {
	flex: 0 1 8%;
	width: 90px;
	justify-content: center;
}
.paybatch-products {
	flex: 0 1 8%;
	width: 90px;
	justify-content: flex-start;
}
.paybatch-amount {
	flex: 0 1 9%;
	width: 100px;
	justify-content: flex-end;
}
.paybatch-status {
	flex: 0 1 10%;
	width: 120px;
	justify-content: flex-end;
	margin-right: 20px;
}
.paybatch-delete {
	flex: 0 1 0;
	width: 0;
	margin-left: 0;
	justify-content: flex-start;
}
.paybatch-processing {
	background: #FFFFE3;
	border: 1px solid #E1E1E1;
	border-radius: 5px;
	padding: 10px 15px;
	font-family: 'Roboto';
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #F39200;	
}
.paybatch-paid {
	font-family: 'Roboto';
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #12AC9D;
	background: #E1FFE6;
	border: 1px solid #E1E1E1;
	border-radius: 5px;
	padding: 10px 15px;
}
.paybatch-open {
	font-family: 'Roboto';
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
	color: #EA596E;
	padding: 10px 15px;
	background: #FFF5D3;
	border: 1px solid #E1E1E1;
	border-radius: 5px;
}
.spayment-column {
	width: 740px;
    margin-bottom: 40px;
}
.spayment-cont {
	background: #FFFFFF;
    border: 1px solid #E1E1E1;
    width: 100%;
    margin: 10px auto 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 25px;
}
.spayment-text, .spayment-title-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #5A5A5A;
}
.spayment-title-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
}
.spayment-box {
	display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 10px 0;
}
.spaybatch-paid {
	font-family: 'Roboto';
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
	color: #ACACAC;
	padding: 10px 15px;
	background: #FFFFFF;
	border: 1px solid #ACACAC;
	border-radius: 5px;
}
.spaybatch-id, .spaybatch-order, .spaybatch-name, .spaybatch-products, .spaybatch-amount, .spaybatch-date {
	display: flex;
}
.spaybatch-id, .spaybatch-order, .spaybatch-name, .spaybatch-products, .spaybatch-amount {
	flex-direction: row;
	align-items: center;
}
.spaybatch-date {
	flex: 0 1 12%;
	width: 100px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	text-align: end;
	margin-right: 20px;
}
.spaybatch-id {
	flex: 0 1 30%;
	width: 100px;
	justify-content: flex-start;
}
.spaybatch-order {
	flex: 0 1 20%;
	width: 100px;
	justify-content: flex-start;
}
.spaybatch-name {
	flex: 0 1 20%;
	width: 100px;
	justify-content: flex-start;
}
.spaybatch-products {
	flex: 0 1 10%;
	width: 100px;
	justify-content: center;
}
.spaybatch-amount {
	flex: 0 1 15%;
	width: 100px;
	justify-content: flex-end;
} 
.spaybatch-create {
	font-family: 'Roboto';
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
	color: #006DD2;
	padding: 10px 15px;
	background: #FFFFFF;
	border: 1px solid #006DD2;
	border-radius: 5px;
}
.spaybatch-not-cont {
	width: 100%;
	background: #FFFFE1;
	border: 1px solid #ACACAC;
	border-radius: 5px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.spaybatch-not-text {
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #5A5A5A;
	margin-left: 10px;
	padding: 5px;
}
.datepicker-material {
  padding: 9px 14px;
  background: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
}  
/* Payment Batch End */

/* Treasury Batch Start */
.treasury-id, .treasury-order, .treasury-seller, .treasury-name, .treasury-country, .treasury-sku, .treasury-products, .treasury-amount, .treasury-date, .treasury-status {
	display: flex;
}
.treasury-id, .treasury-order, .treasury-seller, .treasury-name, .treasury-sku, .treasury-country, .treasury-products, .treasury-amount, .treasury-status {
	flex-direction: row;
	align-items: center;
}
.treasury-date {
	flex: 0 1 6%;
	width: 100px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	text-align: end;
}
.treasury-id {
	flex: 0 1 15%;
	width: 100px;
	justify-content: flex-start;
  overflow-wrap: anywhere;
}
.treasury-order {
	flex: 0 1 12%;
	width: 100px;
	justify-content: flex-start;
}
.treasury-seller {
	flex: 0 1 14%;
	width: 100px;
	justify-content: flex-start;
}
.treasury-sku {
	flex: 0 1 16%;
	width: 150px;
	min-width: 150px;
	justify-content: flex-start;
}
.treasury-name {
	flex: 0 1 12%;
	width: 100px;
	justify-content: center;
}
.treasury-country {
	flex: 0 1 8%;
	width: 100px;
	justify-content: center;
}
.treasury-products {
	flex: 0 1 8%;
	width: 100px;
	justify-content: center;
}
.treasury-amount {
	flex: 0 1 10%;
	width: 100px;
	justify-content: center;
}
.treasury-status {
	flex: 0 1 10%;
	width: 100px;
	justify-content: center;
}

.billing-lines {
  border-collapse: separate;
  border-spacing: 0;
}
.billing-lines .table-results-title {
  justify-content: space-between;  
  border-right: 0;
  border-left: 0;
}

.billing-lines .product {
  flex: 0 1 77%;
  width: 100px;
  justify-content: flex-start;
  text-align: left;
  padding-left: 95px;
}

.billing-lines .items {
  flex: 0 1 8%;
  width: 100px;
  justify-content: flex-start;
}

.billing-lines .price {
  flex: 0 1 15%;
  width: 100px;
  justify-content: flex-start;
}

.billing-lines td  {
  font-weight: 400;
}

.tagType {
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 50px;
  padding: 4px;
  font-weight: bold;
  font-size: 0.85em;
  margin-left: 8px;
  width: 25px;
  height: 25px;
  text-align: center;
}

/* Treasury Batch End */
.MuiAccordionSummary-root {
  user-select: text !important  ;
}