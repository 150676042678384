.profile-form-text {
    font-family: K2D, sans-serif;
    color: #262626;
    font-size: 22px;
    text-align: center;
    padding-bottom: 10px;
    margin-right: 20px;;
}
.profile-change-button {
    display: flex;
    width: 250px;
    height: 45px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 4px 4px rgb(0 0 0 / 8%);
    color: #444;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
}
.profile-box-image {
    width: 150px;
    height: 150px;
    border-radius: 20px;
    background-image: url(https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}
.profile-text-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex: 1;
}
.profile-username {
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: Inter, sans-serif;
    color: #444;
    font-size: 22px;
}
.profile-email {
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: Inter, sans-serif;
    color: #444;
    font-size: 22px;
}
.change-form {
    padding: 20px 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
}
.profile-form-input {
    width: 200px;
	height: 34px;
	margin-bottom: 20px;
	border: 1px solid #c9cacc;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 5px;
}
@media screen and (max-width: 991px) {
    .profile-text-box {
        margin-right: auto;
        margin-left: auto;
        flex-direction: row;
    }
}
@media screen and (max-width: 769px) {
    .profile-text-box {
        margin-right: auto;
        margin-left: auto;
        padding-right: 15px;
        padding-left: 15px;
        flex-direction: column;
        justify-content: flex-start;
    }
    .change-form {
        align-items: center;
    }
}
