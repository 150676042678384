.queue-platform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.queue-process,
.queue-name,
.queue-status,
.queue-user,
.queue-date,
.queue-report {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5px;
}
.queue-process {
  width: 130px;
  flex: 0 1 20%;
}
.queue-process span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.queue-platform {
  width: 80px;
  flex: 0 1 8%;
}
.queue-name {
  width: 150px;
  flex: 0 1 14%;
}
.queue-status {
  width: 80px;
  flex: 0 1 10%;
}
.queue-user {
  width: 250px;
  flex: 0 1 25%;
}
.queue-date {
  width: 120px;
  flex: 0 1 10%;
}
.queue-report {
  width: 100px;
  flex: 0 1 10%;
}
.queue-user-image {
  width: 52px;
  height: 52px;
  background: #f39200;
  border-radius: 100%;
}
.queue-waiting,
.queue-error,
.queue-running,
.queue-finished {
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
.queue-waiting {
  color: #5a5a5a;
  background: #ffffff;
}
.queue-error {
  color: #ea596e;
  background: #fff5d3;
}
.queue-running {
  color: #12ac9d;
  background: #e1ffe6;
}
.queue-finished {
  color: #2b5ec5;
  background: #e2ecff;
}
