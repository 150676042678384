.unregistered-table-cont {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #ffffff;
	border-radius: 5px 5px 0px 0px;
	margin: 0 auto;
	width: 99.7%;
	min-height: 60px;
	max-width: 2560px !important;
}
.unregistered-table-search-cont {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.unregistered-table-search-form {
	width: 300px;
	height: 40px;
	background: #ffffff;
	border: 1px solid #acacac;
	box-sizing: border-box;
	border-radius: 20px;
	margin-right: 20px;
	display: flex;
}
.unregistered-table-search-input {
	width: 80%;
	height: 30px;
	margin: auto;
	background: #ffffff;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px !important;
	line-height: 16px !important;
	color: #acacac !important;
}
.unregistered-table-search-input:focus {
	border: none;
}
.unregistered-table-search-image {
	margin-right: 10px;
}
.unregistered-table-value {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 21px;
	line-height: 25px;
	text-transform: uppercase;
	color: #5a5a5a;
	margin-right: 20px;
}
.unregistered-table-main-title-text {
	font-size: 14px;
	line-height: 30px;
	text-transform: uppercase;
	color: #acacac;
}
.unregistered-table-pagination {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.unregistered-table-header {
	position: sticky;
	z-index: 101;
	top: 2px;
	width: 100%;
}
.unregistered-table-results {
	position: relative;
	width: 100%;
	min-height: 500px;
	padding-left: 0;
}
.unregistered-table-results-title {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	background: #f2f2f2;
	border-radius: 0px;
	text-align: center;
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a !important;
	height: 70px;
	border: 1px solid #e1e1e1;
	box-sizing: border-box;
	position: relative;
	margin-bottom: 0px;
	width: 100%;
	margin-top: -5px;
}
.unregistered-table-overflow {
	position: relative;
	display: block;
	overflow: auto;
	width: 100%;
	height: max-content;
	margin: 0 auto;
	max-width: 100vw !important;
	height: 77vh;
	overflow-y: auto;
	margin-bottom: 20px;
}
.unregistered-small-cell {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 0 12%;
	text-align: center;
	height: 100%;
	margin-right: 20px;
}
.unregistered-big-cell {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex: 0 28%;
	text-align: center;
	height: 100%;
}
.unregistered-biggest-cell {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex: 0 35%;
	text-align: center;
	height: 100%;
	padding-left: 15px;
}
.unregistered-middle-cell {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 0 15%;
	text-align: center;
	height: 100%;
}
.unregistered-arrow-cell {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 0 3%;
	text-align: center;
	height: 100%;
}
.unregistered-btn-upload,
.unregistered-btn-finished,
.unregistered-btn-progress {
	height: 32px;
	border-radius: 5px;
	padding: 8px 10px;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: center;
	box-shadow: none;
	z-index: 100;
}
.unregistered-btn-upload {
	background: #ffffff;
	border: 1px solid #55718a;
	color: #55718a;
}
.unregistered-btn-finished {
	background: #ffffff;
	border: 1px solid green;
	color: green;
	width: 100px;
	font-weight: 500;
}
.unregistered-btn-finished:hover {
	background: green;
	color: #ffffff;
}
.unregistered-btn-finished:hover img {
	filter: grayscale(1) brightness(3);
}
.unregistered-btn-finished-seller {
	font-weight: 600;
	font-size: 14px;
	color: #4cab2b;
	text-transform: uppercase;
}
.unregistered-btn-progress {
	background: #ffedb0;
	border: 1px solid #acacac;
	color: #5a5a5a;
	width: 120px;
	font-weight: 500;
}
.unregistered-font-light {
	font-size: 14px;
	font-weight: 400;
	color: #f1692e;
}
.unregistered-font-file {
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	color: #4cab2b;
}
.unregistered-font-bold {
	font-size: 14px;
	font-weight: 700;
	color: #f1692e;
}
.unregistered-table-result-box {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	background-color: #fff;
	border-bottom: 1px solid #e1e1e1;
	height: 80px;
	border-left: 1px solid #e1e1e1;
	border-right: 1px solid #e1e1e1;
	box-sizing: border-box;
}
.unregistered-table-result-box div {
	border-top: none;
}
.unregistered-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	color: #5a5a5a;
}
.unregistered-table-border-right {
	border: 0.5px solid #e1e1e1;
	height: 100%;
}
.pc-modal-cont {
	position: relative;
	display: flex;
	margin-right: auto;
	margin-left: auto;
	flex-direction: column;
	align-items: flex-start;
	max-width: 420px;
	padding: 10px;
}
.pc-modal-close {
	position: absolute;
	top: 0;
	right: 0;
	padding: 5px;
	color: #5a5a5a;
}
.pc-modal-title-box {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 8px;
}
.pc-modal-title-text {
	font-weight: bold;
	font-size: 21px;
	line-height: 25px;
	color: #5a5a5a;
}
.pc-modal-main-text {
	color: #5a5a5a;
	font-size: 14px;
	font-weight: 600;
	text-align: left;
}
.pc-modal-text {
	font-size: 14px;
	font-weight: 400;
	text-align: left;
	color: #5a5a5a;
	margin-bottom: 15px;
}
.pc-modal-spreadsheet-button {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	border: 1px solid #006dd2;
	background-color: #fff;
	padding: 5px 10px;
	border-radius: 5px;
}
.pc-modal-spreadsheet-button-text {
	font-weight: 500;
	font-size: 14px;
	color: #006dd2;
	padding-left: 10px;
}
.pc-modal-date-text {
	font-size: 12px;
	font-weight: 400;
	color: #5a5a5a;
	margin-top: 5px;
	margin-bottom: 20px;
}
.pc-modal-small-text {
	font-size: 10px;
	font-weight: 300;
	color: #5a5a5a;
}
.pc-modal-file-name-text {
	font-size: 14px;
	font-weight: 600;
	color: #55718a;
	padding-left: 10px;
}
.pc-modal-file-border {
	width: 100%;
	height: 0px;
	border: 0.5px solid #e1e1e1;
	margin-bottom: 20px;
}
.pc-modal-dropzone {
	background: #f2f2f2;
	width: 100%;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 10px 0px;
}
.pc-modal-dropzone-text {
	font-weight: normal;
	font-size: 14px;
	color: #c4c4c4;
}
input[type="file"] {
	display: none;
}

@media screen and (max-width: 991px) {
	.unregistered-table-search-cont {
		flex-direction: column;
	}
	.unregistered-table-cont {
		flex-direction: column;
	}
	.unregistered-table-cont div {
		margin: 5px 0;
	}
}

@media screen and (max-width: 767px) {
	.unregistered-table-search-form {
		margin-right: 0;
	}
	.unregistered-table-value {
		padding-left: 0;
		margin-bottom: 10px;
	}
	.unregistered-text {
		margin: 10px 0;
	}
	.pc-modal-cont {
		width: 100%;
		overflow: overlay;
		height: 700px;
		padding: 20px 0;
	}
	.pc-modal-spreadsheet-button {
		margin-right: 0;
		margin-top: 20px !important;
	}
}
.batch-integrated-cont {
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;
	padding: 10px 0;
	display: flex;
}
.batch-integrated-box {
	display: flex;
	width: 100%;
	flex-direction: row;
	flex: 0 1 45%;
	align-items: center;
	justify-content: flex-start;
	margin: 10px 5px;
}
.pbl-image {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex: 0 1 5%;
	padding-right: 5px;
	text-align: center;
	min-width: 80px;
	height: 100%;
}
.pbl-ean {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex: 0 1 15%;
	padding-right: 5px;
	text-align: start;
	min-width: 120px;
	height: 100%;
}
.pbl-small {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex: 0 1 10%;
	padding-right: 5px;
	text-align: start;
	min-width: 100px;
	height: 100%;
}
.pbl-title {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex: 0 1 50%;
	padding-right: 5px;
	text-align: start;
	min-width: 300px;
	height: 100%;
}
.prod-batch-logs-modal-btn {
	color: #006dd2 !important;
	text-align: center !important;
	font-family: Roboto !important;
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: normal !important;
	width: 120px !important;
	height: 50px !important;
	margin: 0 5px !important;
	border: 1px solid #acacac !important;
	border-radius: none !important;
	box-shadow: none !important;
}
.prod-batch-logs-modal-btn:active,
.prod-batch-logs-modal-btn:focus {
	color: #fff !important;
	background: #006dd2 !important;
}
