
.file-batch-upload-wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
}
.file-batch-upload-gray-text{
    color:#5a5a5a;
    font-size: 13px;
}
.file-batch-upload-sidebar{
    width: 25%;
    background-color: white;
    padding: 29px 0;

}
.file-batch-upload-sidebar-header-text{
   font-weight: 700;
   color:#5a5a5a;
   margin: 0 20px;
   margin-bottom: 15px;
}

.file-batch-upload-sidebar-select-items{
    display: flex;
    align-items: center;
    padding: 5px 20px;
    margin-bottom: 2px;
}
.file-batch-upload-sidebar-select-item-image{
  height: 36px;
  width: 36px;
  margin: 0 10px;
}
.file-batch-upload-main{
    width: 75%;
    background-color:  #E8EDF2;
    padding: 20px 30px 60px;
    /* overflow-y: auto; */
}

.file-batch-selected-droppable-over-container{
    border: 2px;
    border-style: dashed;
    border-color: #ACACAC;
    height: 240px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.file-batch-selected-droppable-over-text{
    font-size: 20px;
    color: #2B5EC5;
    text-align: center;
}

.file-batch-save-items-modal-container{
    color: #5a5a5a;
    width: 70%;
    padding: 30px;
    max-height: 80%;
}
.file-batch-save-items-modal-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.file-batch-save-items-modal-text1{
    font-weight: 700;
    color:#5a5a5a;
    padding-bottom: 15px;
    font-size: 20px;
    border-bottom: 1px solid #e1e1e1;
    text-align: start;
    width: 100%;
    margin-bottom: 16px;
}
.file-batch-save-items-modal-text2{
    color:#5a5a5a;
    font-size: 14px;
    text-align: start;
}
.file-batch-sku-ean-container{
  display: flex;
  color:#5a5a5a;
  font-size: 14px;
  text-align: start;
  margin-top: 13px;
}

.file-batch-assets-container{
    margin-top: 50px;
    width: 100%;
}
.file-batch-image-asset-container{
  color:#5a5a5a;
  font-size: 14px;
  text-align: start;
}
.file-batch-sort-items-container{
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  z-index: 100000000000;
}
.file-batch-sort-items-detail-container{
  display: flex;
  align-items: center;
  flex: 1;
  border-radius: 20px;
  border: 1px solid #e1e1e1;
  padding: 12px;
  margin: 0 20px;
}
.file-batch-sort-items-detail-info-container{
margin-left: 15px;

}
.file-batch-save-container{
display: flex;
width: 100%;
align-items: center;
justify-content: space-between;
border-top: 1px solid #e1e1e1;
padding-top: 20px;
margin-top: 30px;
}
.file-batch-save-button{
height: 42px;
background-color: 
#55718A;
font-weight: 700;
}

