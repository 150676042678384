.dropdown {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: white;
  z-index: 1200;
  background: rgba(0, 0, 0, 0.2);
}
.dropdown__body {
  max-width: 350px;
  height: auto;
  border-radius: 10px;
  text-align: center;
  position: absolute;
  left: 250px;
  top: 120px;
  background: #ffffff !important;
  border-radius: 0;
  box-shadow: none !important;
}
.dropdown__body_edit {
  width: initial;
  min-width: 400px;
}
.dropdown__body_schedule {
  width: initial;
  min-width: 400px;
  height: 350px;
}
.dropdown__body_form {
  width: 500px;
  height: 500px;
  margin: auto;
  background: #ffffff;
  margin-top: auto;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}
.dropdown__close-button {
  position: absolute;
  top: -7px;
  right: 3px;
  color: rgba(57, 57, 57, 0.66);
  cursor: pointer;
  font-size: 35px;
}
.dropdown__close-button:hover {
  transform: scale(1.2);
}
@media screen and (max-width: 769px) {
  .dropdown__body {
    margin: 0 10px !important;
  }
}
