.s_main-login {
  position: static;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: #314455;
  flex-direction: column;
}
.login-title-box {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0 40px;
  justify-content: center;
}
.login-title-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 19px;
  color: #393939;
}
.login-box {
  width: 420px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  font-family: K2D, sans-serif;
  padding: 30px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.in-column {
  display: flex;
  justify-content: center;
  align-items: center;
}
.email_text_box {
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 10px;
  justify-content: space-between;
}
.email-text-login {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #5a5a5a;
}
.password-text-login {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #5a5a5a;
}
.email-field {
  padding: 10px;
  width: 100%;
}
.password-field {
  padding: 10px;
  width: 100%;
}
.log-in-login {
  background: #ee5600;
  border-radius: 6px;
  width: 100%;
  padding: 12px 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  text-transform: initial;
}
.button_box {
  display: flex;
  margin-top: 20px;
  text-align: center;
}
.esqueci-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #215280;
}
.esqueci-text a {
  cursor: pointer;
}
.esqueci_text_box {
  width: 320px;
  color: #5a5a5a;
}
.esqueci_text_box h3 {
  margin-bottom: 15px;
  text-transform: uppercase;
}
.esqueci_text_box p {
  margin-bottom: 10px;
}
.login-input {
  width: 320px;
  display: flex;
  height: 42px;
  padding: 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  border-radius: 20px;
  background: #ffffff;
  background: #f4f4f4;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 30px;
}
.lembre-me-box {
  width: 120px;
}
.lembre-me {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #5a5a5a;
}
.login-text-box {
  display: flex;
  align-items: center;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 10px;
  justify-content: space-between;
}
.lingua-transform {
  transform: translate(42px, 2px) scale(0.75) !important;
}
.contato {
  width: 450px;
  margin: 20px 0 10px;
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 14px;
}
.login-lg-text {
  border-bottom: 1px solid #e1e1e1 !important;
}
.login-lg-text:last-child {
  border-bottom: 1px solid transparent !important;
}
.contato a:hover {
  text-decoration: underline;
}

.wait {
  opacity: 0.5;
  cursor: wait;
}

@media screen and (max-width: 767px) {
  .login-form-block {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 479px) {
  .s_main-login {
    padding-top: 20px;
    justify-content: flex-start;
    height: 100%;
  }
  .login-box {
    width: 100%;
  }
  .login-input {
    width: 280px;
  }
  .login-box {
    width: 100%;
    padding: 30px 0;
  }
  .contato {
    width: 96%;
  }
}
