.bpn-cont {
	width: 100%;
	min-height: 48px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.bpn-title-cont {
	flex: 1 1 auto;
	width: 100%;
}
.bpn-tab {
	max-width: 360px;
	min-width: 90px;
	padding: 12px 16px;
	font-weight: 500;
	font-size: 15px;
	line-height: 21px;
	color: #006dd2;
	margin-right: 20px;
}
.bpn-tab:active,
.bpn-tab:focus,
.bpn-tab:hover {
	color: #5a5a5a;
	border-bottom: 1px solid #5a5a5a;
}
.bpn-tab-active {
	font-weight: bold;
	color: #5a5a5a;
	border-bottom: 1px solid #5a5a5a;
}
