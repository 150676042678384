.card-btns-cont {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}
.card-btn-cont {
  height: 110px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: 500;
  transition: 0.3s all ease-in-out;
  margin: 0 15px;
}
.card-btn-cont:first-child {
  margin: 0 15px 0 0;
}
.card-btn-cont:hover {
  cursor: pointer;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.12);
  transition: 1s;
}
.card-btn-cont-active {
  background: linear-gradient(
    104.34deg,
    #f1692e -15.34%,
    #f39200 145.95%
  ) !important;
}
.card-btn-cont-active .card-btn-label,
.card-btn-cont-active .card-btn-qty,
.card-btn-cont-active .card-btn-dynamics {
  color: #ffffff !important;
}
.card-btn-cont-active .card-btn-percentage {
  background-color: #ffffff !important;
  color: #f1692e !important;
}
.card-btn-label {
  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
  color: #5a5a5a;
}
.card-btn-qty {
  font-size: 27px;
  font-weight: 700;
  line-height: 32px;
  color: #5a5a5a;
}
.card-btn-percentage-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.card-btn-percentage {
  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  background-color: #acacac;
  color: #ffffff;
  padding: 3px 7px;
  border-radius: 3px;
  margin-right: 5px;
}
.card-btn-dynamics {
  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
  color: #acacac;
}
.card-btn-watch-title {
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  color: #5a5a5a;
}
.card-btn-watch-btn {
  display: flex;
  flex-direction: row;
  border-radius: 30px;
  flex-direction: row;
  padding: 8px 10px;
  color: #f1692e;
  border: 1px solid #f1692e;
}
.card-btn-watch {
  font-size: 12px;
  font-weight: 600;
  margin-left: 7px;
}
.card-brn-discount {
  display: flex;
  align-items: center;
  gap: 6px;
}
.card-brn-discount p {
  background-color: #acacac;
  border-radius: 4px;
  padding: 5px;
  color: white;
  font-weight: 700;
  font-size: 13px;
}
.card-brn-discount span {
  color: #acacac;
}

.card-btn-cont-active .card-brn-discount p {
  background-color: white;
  color: #f1692e;
}
.card-btn-cont-active .card-brn-discount span {
  color: white;
}
