.vendors-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
}
.vendors-mapping-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 15px;
    width: 500px;
}
.vendor-text {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ACACAC;
}
.vendor-mapping-modal-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #5A5A5A;    
}