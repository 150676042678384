.onboarding-steps-title {
	font-size: 20px;
	line-height: 24px;
	color: #5a5a5a;
}
.onb-box {
	background: #ffffff;
	border-radius: 10px;
	width: 100%;
	padding: 30px 40px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	overflow: auto;
	padding-bottom: 40px;
}
.onboarding-steps-title-cont {
	font-size: 18px;
	line-height: 20px;
	color: #5a5a5a;
}
.onboarding-video-test {
	background: #5a5a5a;
	height: 200px;
	width: 100%;
}
.onb-text1 {
	color: #5a5a5a;
	font-family: Roboto;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 25px;
}
.onb-text {
	color: #5a5a5a;
	font-family: Roboto;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 17px;
}
.onb-s2-title {
	color: #5a5a5a;
	text-align: center;
	font-family: Roboto;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 30px;
	background: #f2f2f2;
	width: 100%;
	padding: 15px 0px;
}
.onb-s2-cont {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	height: 300px;
	overflow: auto;
}
.onb-s2-box {
	flex: 1 1 45%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	margin: 10px 0;
	padding: 10px 10px;
}
.onb-s2-box:hover {
	background: #f2f2f2;
}
.onb-text2 {
	color: #5a5a5a;
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
.onb-text3 {
	color: #acacac;
	font-family: Roboto;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.onb-vertical-border {
	background: #e1e1e1;
	width: 2px;
	height: 120px;
	margin-right: 20px;
}
.onb-modal-text {
	color: #5a5a5a;
	font-family: Roboto;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 25px;
	text-align: center;
}
