.Toastify__toast--error {
	background-color: #fbb;
	color: #c80000;
}

.Toastify__toast--warning * {
	color: #333;
}

.toast-message-container * {
	z-index: 100000;
}
