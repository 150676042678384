.singlemarketplace-title-main {
  width: 100%;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  justify-content: flex-start;
  align-items: center;
  background: #e2e2e2;
  padding: 30px 0 25px;
}

.sMarketplace__generateBtn {
  margin: auto;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  font-family: Inter, sans-serif;
  color: #444;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

.sMarketplace__buttons {
  display: flex;
  max-width: 600px;
  margin: auto;
}

.singlemarketplace-title-cont {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding-left: 40px;
}

.singlemarketplace-title-image {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
}

.singlemarketplace-title-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #5a5a5a;
  margin-left: 5px;
}

.singlemarketplace-title-marketplace-box {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #5a5a5a;
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.trash-icon {
  padding-left: 20px;
}

.trash-icon:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.singlemarketplace-title-buttons-box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.singlemarketplace-edit-button {
  background: #55718a;
  border-radius: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 10px;
  width: 72px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.singlemarketplace-sku {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 0 12%;
  padding-right: 5px;
  text-align: center;
  width: 140px;
  height: 100%;
}

.singlemarketplace-ean {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 0 12%;
  padding-right: 5px;
  text-align: center;
  width: 140px;
  height: 100%;
}

.singlemarketplace-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 0 40%;
  padding-right: 5px;
  text-align: center;
  width: 420px;
  height: 100%;
  padding-left: 10px;
}

.singlemarketplace-vendors {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 0 20%;
  padding-right: 5px;
  text-align: center;
  width: 200px;
  height: 100%;
  text-align: start;
}

.singlemarketplace-stock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 12%;
  padding-right: 5px;
  text-align: center;
  width: 140px;
  height: 100%;
}

.singlemarketplace-price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex: 0 7%;
  padding-right: 15px;
  width: 100px;
  height: 100%;
}

.singlemarketplace-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 12%;
  padding-right: 5px;
  text-align: center;
  width: 140px;
  height: 100%;
}

.singlemarketplace-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #5a5a5a;
}

.singlemarketplace-table-edit-button {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: #acacac;
  border: none;
  box-shadow: none;
  background: none;
  text-transform: lowercase;
}

.singlemarketplace-addproducts-button {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #acacac;
  box-shadow: none;
  border: none;
  background: transparent;
  padding: 0;
  margin-top: 5px;
}

.single-main-title-cont {
  display: flex;
  width: 100%;
  margin: 0 15px;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.single-main-title-start,
.single-main-title-end {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.single-main-title-start {
  align-items: flex-start;
}

.single-main-title-end {
  align-items: flex-end;
}

.more-cont {
  height: 40px;
  padding: 0;
  margin-top: -2px !important;
  margin-right: 20px !important;
}

.more-cont div {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  min-width: 70px !important;
}

/* Product Catalog Start */
.product-main-cont {
  position: fixed;
  height: 100%;
  overflow: scroll;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #e5e5e5;
  z-index: 1211;
}

.product-header-cont {
  background: #454545;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 150;
}

.product-header-button {
  background: #ff9900 !important;
  height: 100% !important;
  padding: 0 40px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  text-transform: uppercase !important;
  color: #2b2b2b !important;
  margin-right: 0 !important;
  border-radius: 0px !important;
}

.product-exit-btn {
  height: 100%;
  padding: 0 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #868686;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #ffffff;
}

.product-header-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.product-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
  min-height: 100%;
}

.product-h {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  color: #5a5a5a;
}

.product-p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #5a5a5a;
  margin-top: 5px;
}

.product-content {
  display: flex;
  flex-direction: column;
  max-width: 1380px;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #5a5a5a;
  margin-top: 40px;
}

.product-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: start;
  min-width: 600px;
}

.product-content-title {
  min-width: 380px;
  width: 100%;
}

.product-input-cont {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  min-width: 380px;
  width: 100%;
  margin: 15px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #5a5a5a;
}

.product-input,
.product-select {
  background: #f8f8f8;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  width: 100%;
  height: 40px;
  padding-left: 10px;
}

.product-input::placeholder {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #5a5a5a;
}

.product-add-cont-order {
  border: 1px dashed #5a5a5a;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 25px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #006dd2;
}

.product-main-btn {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #ffffff;
  background: #55718a;
  border-radius: 5px;
  padding: 12px 15px;
  margin-left: 14px;
}

.product-disabled {
  opacity: 0.3;
}

.product-back-btn {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  text-transform: uppercase !important;
  color: #acacac !important;
  background: #454545 !important;
  border: none !important;
  box-shadow: none !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  margin-right: 10px !important;
}

.product-attributes {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 10px;
  margin-top: -20px;
}

.product-schema {
  display: flex;
  width: 60%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 10px;
  margin-top: -20px;
}

.product-match-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #5a5a5a;
  text-transform: initial;
}

.product-match-edit {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #acacac;
  background: transparent;
  border: none;
  box-shadow: none;
}

.product-match-subtitle {
  width: 100%;
  padding: 10px 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #a4a4a4;
  text-transform: lowercase;
}

.product-sub-schema-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #acacac;
  display: flex;
  width: 100%;
  text-transform: initial;
}

.product-list-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #5a5a5a;
  text-transform: initial;
}

.product-list-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #006dd2;
  text-transform: initial;
}

.batch-pr-step {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.batch-pr-step-text {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #ffffff !important;
  height: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}

.batch-pr-step-text span {
  color: #ffffff !important;
}

.batch-step-vector {
  margin-left: 20px;
}

.batch-link-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #006dd2;
  text-decoration: underline;
}

.batch-upload-content-cont {
  background: #ffffff;
  border-radius: 10px;
  padding: 40px;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.batch-upload-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #5a5a5a;
  text-transform: initial;
}

.batch-upload-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #acacac;
  text-transform: initial;
  border-bottom: 1px solid #e1e1e1;
  padding: 15px 0;
}

.batch-upload-border {
  border-top: 1px solid #e1e1e1 !important;
  width: 100%;
}

.batch-platform-search-cont {
  background: #ffffff;
  border-radius: 10px;
  padding: 25px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;
}

.batch-search-remove {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #acacac;
  margin-left: 20px;
}

.batch-search-variants {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
}

.batch-search-variant {
  flex: 0 0 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #006dd2;
  border-radius: 4px;
  padding: 10px 13px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #ffffff;
  margin-right: 10px;
  min-width: 150px;
  justify-content: space-between;
  margin-bottom: 10px;
}

.batch-platform-cont {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
  justify-content: flex-start;
}

.batch-platform-box {
  flex: 0 1 24%;
  display: flex;
  background: #ffffff;
  border-radius: 10px;
  margin: 0 5px 45px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.batch-platform-text-cont {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 15px 25px;
  border-top: 1px solid #e1e1e1;
}

.batch-marketplace-text {
  text-transform: uppercase;
  color: #acacac;
}

.batch-platform-selected {
  color: #006dd2;
  font-weight: 600;
}

.batch-platform-countries {
  color: #5a5a5a;
  font-weight: 600;
}

.batch-platform-modal-cont {
  background: #ffffff;
  min-height: 600px;
  min-width: 500px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 1300;
}

.batch-platform-modal-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 25px;
  color: #5a5a5a;
  width: 100%;
  margin-bottom: 20px;
}

.batch-platform-modal-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
}

.batch-platform-modal-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e1e1e1;
  padding: 15px 0;
}

.batch-platform-modal-box:last-child {
  border-bottom: none;
}

.platform-button {
  box-shadow: none;
  background: transparent;
  border: 1px solid #acacac;
  padding: 10px 16px;
  font-weight: 500;
  font-size: 14px;
  color: #5a5a5a;
  border-radius: 5px;
}

/* Product Catalog End */
@media screen and (max-width: 991px) {
  .singlemarketplace-title-buttons-box {
    flex-direction: column;
  }
}

pre {
  background: #eee;
  padding: 1rem;
  overflow: auto;
  border-radius: 3px;
  max-width: 80ch;
}

pre code {
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
}

.MuiAutocomplete-option {
  min-height: 48px !important;
}
