.shipping-pais {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* flex: 0 10%; */
  width: 220px;
  height: 100%;
  padding: 0 10px;
}
.freight-reg {
  display: flex;
  align-items: center;
  /* flex: 0 15%; */
  width: 80px;
  height: 100%;
}
.freight-prazo {
  display: flex;
  align-items: center;
  /* flex: 0 10%; */
  width: 40px;
  height: 100%;
}
.freight-column {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 5%;
  width: 100px;
  height: 100%;
  padding-right: 40px;
}
.freight-body {
  font-size: 14px;
  line-height: 16px;
  color: #5a5a5a;
  height: 60px;
}
.freight-modal-box {
  display: flex;
  flex-direction: row;
}
.freight-modal-input {
  font-size: 16px;
  line-height: 30px;
  color: #5a5a5a;
  background: #f8f8f8;
  border: 1px solid #acacac;
  box-sizing: border-box;
  border-radius: 6px;
  width: 100px;
  height: 40px;
  padding-left: 5px;
}
.freight-modal-table-cont {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  text-align: left;
  margin-top: 20px;
}
.freight-modal-table-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 0 50%;
}
.freight-modal-column {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  align-items: center;
  flex: 0 30%;
  max-width: 100px;
  height: 100%;
}
.mapping-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  background-color: white;
  padding: 14px 20px;
  border-radius: 5px;
  width: 100%;
}
.mapping-filter-button {
  color: #55718a;
  display: flex;
  gap: 5px;
  align-items: center;
  box-shadow: none;
  background: transparent;
  border: 1px solid #acacac;
  padding: 10px 18px;
  font-weight: 700;
  font-size: 14px;
  border-radius: 6px;
  flex-direction: row;
}
.origin-modal button {
  color: #55718a;
  box-shadow: none;
  background: transparent;
  border: none;
  padding: 11px 45px;
  font-weight: 700;
  font-size: 14px;
  border-radius: 6px;
  flex-direction: row;
}
@media screen and (max-width: 767px) {
  .freight-modal-table-box {
    flex: 0 1 100%;
  }
  .mapping-modal-results-title,
  .mapping-modal-result-box {
    justify-content: center;
  }
}
